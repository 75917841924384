<!-- @format -->
<!-- @format -->
<script>
	import { objProp } from 'utils/property';
	import { isPresent, isBlank } from 'utils/tools';

	export let url;
	export let linkText;
	export let additionalText;
	export let freeMessage;
	export let consumer;
	export let consumerProp;
	export let resource;
	export let resourceProp;
	export let something;
	export let judicial_act_recognized;

	let hintText = '';
	let previousConsumerValue = objProp(consumer, consumerProp);
	let previousResourceNewValue = objProp(resource, resourceProp);

	$: if (isPresent(consumer)) {
		const consumerValue = objProp(consumer, consumerProp);
		const resourceValue = objProp(resource, resourceProp);

		if (previousConsumerValue !== consumerValue || isBlank(resourceValue)) {
			hintText = 'AI перенесёт данные в';
		} else if (!consumerValue || resourceValue === consumerValue || (something && something === consumerValue)) {
			hintText = (previousConsumerValue === consumerValue && isPresent(resourceValue)) || !consumerValue ? 'AI заполнил из' : 'AI перенесёт данные в';
		} else {
			hintText = 'Изменено пользователем';
		}
	} else {
		hintText =
			isPresent(objProp(resource, resourceProp)) && previousResourceNewValue === objProp(resource, resourceProp) ? 'AI заполнил из' : 'AI перенесёт данные в';
	}
</script>

{#if isPresent(judicial_act_recognized)}
	<span class="form-text text-muted m-b-none m-l-xs">
		{judicial_act_recognized}
	</span>
{:else if url || freeMessage || linkText || additionalText}
	<span class="form-text text-muted m-b-none m-l-xs">
		{#if url}
			{hintText}
			{#if hintText !== 'Изменено пользователем'}
				<span class="hint-link" role="button" tabindex="0" on:click={() => (location.href = url)} on:keypress|stopPropagation>
					{linkText}
				</span>
			{/if}
		{:else if freeMessage}
			{freeMessage}
		{:else if linkText || additionalText}
			{`AI заполнил на основе ${linkText}${additionalText || ''}`}
		{/if}
	</span>
{/if}

<style>
	.hint-link {
		text-decoration: underline;
		cursor: pointer;
	}
</style>
