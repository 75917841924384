<!-- @format -->
<!-- @format -->
<script>
	import { isPresent } from 'utils/tools';
	import moment from 'moment';

	export let id;
	export let name;
	export let value;
	export let disabled;
	export let klass;
	export let onchange = () => {};

	const supportedFormats = [
		'DD.MM.YYYY',
		'MM/DD/YYYY',
		'YYYY-MM-DD',
		'DD-MM-YYYY',
		'MM-DD-YYYY',
		'YYYY/MM/DD',
		'DD/MM/YYYY',
		'YYYY.MM.DD',
		'DD.MM.YY',
		'MM/DD/YY',
		'YY-MM-DD',
	];

	function handlePaste(event) {
		event.preventDefault();
		const pastedData = (event.clipboardData || window.clipboardData).getData('Text');
		let parsedDate = null;

		for (const format of supportedFormats) {
			parsedDate = moment(pastedData, format, true);
			if (parsedDate.isValid()) {
				break;
			}
		}
		if (parsedDate && parsedDate.isValid()) {
			value = parsedDate.format('YYYY-MM-DD');
			onchange();
		}
	}
</script>

<input
	{id}
	{name}
	type="date"
	bind:value
	{disabled}
	on:change={onchange}
	on:paste={handlePaste}
	class={klass}
	class:empty={!isPresent(value)}
	autocomplete="off"
/>

<style>
	/* Хак из-за некорректного объявления класса empty в отчетах */
	.empty {
		padding-bottom: 6px;
		padding-left: 12px;
		padding-right: 12px;
		padding-top: 6px;
		height: calc(1.5em + 0.75rem + 2px);
	}
</style>
