<!-- @format -->
<!-- @format -->
<script>
	import { bankruptIsPerson, bankruptIsOrganization } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { percentFormat, currencyFormat } from 'utils/formats';
	import { isPresent } from 'utils/tools';
	import moment from 'moment';
	import IconHandBackRightOffOutline from '~/svelte/_shared/hand-back-right-off-outline.svelte';

	export let requirement;
	export let relevanceDate = moment().format('YYYY-MM-DD');
	export let openCreditorsRequirementModal = () => {};
	export let openAmendementModal = () => {};

	// определить платежи при реализации погашений требований
	$: requirementPayment = requirement.paid_sum;
	$: requirementPaymentPercent = parseFloat(requirement.amount) === 0 ? 0 : (requirementPayment / parseFloat(requirement.amount)) * 100;
</script>

<tr
	on:click={(($bankruptIsPerson && $procedureAvailable) || $bankruptIsOrganization) && (() => openCreditorsRequirementModal(requirement.id))}
	class:text-disabled={requirement.excluded_date && requirement.excluded_date <= relevanceDate}
	class:excluded={requirement.disabled_by_amendement_id}
>
	<td class="text-center requirement-index">
		{requirement.index}
		{#if requirement.order_of_repayment === 2}
			{#if !requirement.is_voting}
				<div class="requirement-is_voting"><IconHandBackRightOffOutline /></div>
			{/if}
		{/if}
	</td>
	<td class="text-center">{moment(requirement.judicial_act_on_inclusion_date).format('DD.MM.YYYY')}</td>
	<td>{requirement.counterparty_name}</td>
	<td class="text-center">{requirement.counterparty_number}</td>
	<td class="text-right">{requirement.amount ? currencyFormat(requirement.amount) : '0,00'}</td>
	<td class="text-right">{requirementPayment ? currencyFormat(requirementPayment) : '0,00'}</td>
	<td class="text-right">{requirementPaymentPercent ? percentFormat(requirementPaymentPercent) : '0,00%'}</td>
	<td class="text-right">{currencyFormat((requirement?.amount || 0) - (requirementPayment || 0))}</td>
	<td class="actions">
		{#if isPresent(requirement.disabled_by_amendement_id)}
			исключено
		{:else}
			<button class="btn btn-sm btn-warning float-left m-r-sm text-nowrap" on:click|stopPropagation={() => openAmendementModal(requirement.id)}>
				+ Изменение
			</button>
		{/if}
	</td>
</tr>

<style>
	tr:nth-of-type(odd) {
		background-color: rgba(0, 0, 0, 0.025) !important;
	}
	.requirement-index {
		position: relative;
	}
	.requirement-is_voting {
		height: 20px;
		position: absolute;
		left: 7px;
		top: 5px;
		color: grey;
	}
</style>
