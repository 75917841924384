<!-- @format -->
<script>
	import { procedure, newObject } from 'base_stores';
	import { dct, currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDatepicker from '~/svelte/components/ui/autosave_datepicker.svelte';

	const labels = {
		reports: 'Дата, на которую проведен финанализ',
		financial_analysis: 'Дата, на которую проводится анализ',
	};

	$: procedureId = $procedure && $procedure.id;

	$: financialAnalysisDate = {
		...$commonParams,
		attribute: 'financial_analysis_date',
		label: labels[$dct.category],
		value: $currentData && $currentData.financialAnalysisDate,
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		hintData:
			($procedure && $procedure.introduction_date) === ($currentData && $currentData.financialAnalysisDate)
				? {
						url: `/procedures/${procedureId}`,
						linkText: 'Процедура',
						consumerNew: $newObject,
						resource: $procedure,
						resourceProp: 'introduction_date',
					}
				: { freeMessage: 'Изменено пользователем' },
	};
</script>

<AutosaveDatepicker {...financialAnalysisDate} />
