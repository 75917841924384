<!-- @format -->
<!-- @format -->
<script>
	import { transmitter } from 'base_stores';
	import { isPresent } from 'utils/tools';
	import SubTitleRow from './_sub_title_row.svelte';
	import CreditorRequirementRow from './_creditor_requirement_row.svelte';
	import numberingRtk from './_numbering';

	export let relevanceDate;
	export let mounted = false;
	export let openCreditorsRequirementModal = () => {};
	export let openAmendementModal = () => {};

	const tableTitles = [
		'Номер требования',
		'Дата записи требования',
		'Наименование или ФИО кредитора',
		'Номер кредитора',
		'Сумма требования (руб.)',
		'Сумма оплаты (руб.)',
		'Процент оплаты',
		'Остаток требования (руб.)',
	];

	const finesKinds = ['Проценты за просрочку платежа', 'Пени', 'Штраф', 'Проценты за пользование чужими денежными средствами (по ст.395 ГК)', 'Неустойка'];

	let creditorsRequirementsInReestr = [];
	let creditorsRequirementsOffReestr = [];

	$: creditorsRequirements = ($transmitter && $transmitter.creditors_requirements) || creditorsRequirements || [];

	$: filteredByRelevanceDateCreditorsRequirements =
		(creditorsRequirements && creditorsRequirements.filter(requirement => requirement.judicial_act_on_inclusion_date <= relevanceDate)) || [];
	$: creditorsRequirementsInReestr =
		(filteredByRelevanceDateCreditorsRequirements && filteredByRelevanceDateCreditorsRequirements.filter(requirement => !requirement.off_reestr)) || [];
	$: creditorsRequirementsOffReestr =
		(filteredByRelevanceDateCreditorsRequirements && filteredByRelevanceDateCreditorsRequirements.filter(requirement => requirement.off_reestr)) || [];

	$: if (creditorsRequirementsInReestr) creditorsRequirementsInReestr = numberingRtk(creditorsRequirementsInReestr);
	$: if (creditorsRequirementsOffReestr) creditorsRequirementsOffReestr = numberingRtk(creditorsRequirementsOffReestr);

	// требования первой очереди
	$: firstStage = (creditorsRequirementsInReestr && creditorsRequirementsInReestr.filter(requirement => parseInt(requirement.order_of_repayment) === 1)) || [];
	// требования второй очереди
	$: secondStage = (creditorsRequirementsInReestr && creditorsRequirementsInReestr.filter(requirement => parseInt(requirement.order_of_repayment) === 2)) || [];
	// требования третьей очереди ВСЕ
	$: thirdStage = (creditorsRequirementsInReestr && creditorsRequirementsInReestr.filter(requirement => parseInt(requirement.order_of_repayment) === 3)) || [];
	// требования третьей очереди обеспеченные залогом, исключая пени и штрафы
	$: thirdStageSecuredByPledge =
		(thirdStage && thirdStage.filter(requirement => requirement.secured_by_pledge && !finesKinds.includes(requirement.kind))) || [];
	// требования третьей очереди не обеспеченные залогом, исключая пени и штрафы
	$: thirdStageNotSecuredByPledge =
		(thirdStage && thirdStage.filter(requirement => !requirement.secured_by_pledge && !finesKinds.includes(requirement.kind))) || [];
	// проценты
	$: thirdStagePercents = [];
	// пени, штрафы
	$: thirdStageFines = (thirdStage && thirdStage.filter(requirement => finesKinds.includes(requirement.kind))) || [];
	// требования, кроме исключённых и учитываемых за реестром
	$: allBesidesExcludedAndOffReestr =
		(creditorsRequirementsInReestr &&
			creditorsRequirementsInReestr.filter(requirement => !requirement.excluded_date || requirement.excluded_date > relevanceDate)) ||
		[];
	// требования, кроме исключённых
	$: allBesidesExcluded =
		(creditorsRequirementsInReestr &&
			creditorsRequirementsOffReestr &&
			creditorsRequirementsInReestr
				.concat(creditorsRequirementsOffReestr)
				.filter(requirement => !requirement.excluded_date || requirement.excluded_date > relevanceDate)) ||
		[];

	const sums = (selection = []) => {
		const amount = selection
			.filter(requirement => !requirement.disabled_by_amendement_id)
			.reduce((totalAmount, requirement = 0) => totalAmount + parseFloat(requirement.amount ? requirement.amount : 0), 0);
		const payment = selection
			.filter(requirement => !requirement.disabled_by_amendement_id)
			.reduce((totalAmount, requirement = 0) => totalAmount + parseFloat(requirement.paid_sum ? requirement.paid_sum : 0), 0);
		const paymentPercent = amount === 0 ? 0 : (payment / amount) * 100;

		return {
			amount: amount,
			payment: payment,
			paymentPercent: paymentPercent,
		};
	};
</script>

<table class="table table-mobile creditors_requirements__table">
	<thead class="thead-light">
		<tr>
			{#each tableTitles as title}
				<th class="text-center p-w-xs">
					{title}
				</th>
			{/each}
			<th class="actions"></th>
		</tr>
	</thead>
	<tbody>
		<SubTitleRow title="Первая очередь" sums={sums(firstStage)} />
		{#if !mounted}
			<tr><td class="text-secondary text-center" colspan={tableTitles.length}><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
		{:else if isPresent(firstStage)}
			{#each firstStage as firstStageRequirement}
				<CreditorRequirementRow requirement={firstStageRequirement} {openCreditorsRequirementModal} {openAmendementModal} {relevanceDate} />
			{/each}
		{:else}
			<tr class="no-data"><td class="text-secondary text-center" colspan={tableTitles.length}>Нет данных</td></tr>
		{/if}
		<SubTitleRow title="Вторая очередь" sums={sums(secondStage)} />
		{#if !mounted}
			<tr><td class="text-secondary text-center" colspan={tableTitles.length}><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
		{:else if isPresent(secondStage)}
			{#each secondStage as secondStageRequirement}
				<CreditorRequirementRow requirement={secondStageRequirement} {openCreditorsRequirementModal} {openAmendementModal} {relevanceDate} />
			{/each}
		{:else}
			<tr class="no-data"><td class="text-secondary text-center" colspan={tableTitles.length}>Нет данных</td></tr>
		{/if}
		<SubTitleRow title="Третья очередь, в т.ч." sums={sums(thirdStage)} />
		<SubTitleRow title="Третья очередь: требования, обеспеченные залогом" sums={sums(thirdStageSecuredByPledge)} />
		{#if !mounted}
			<tr><td class="text-secondary text-center" colspan={tableTitles.length}><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
		{:else if isPresent(thirdStageSecuredByPledge)}
			{#each thirdStageSecuredByPledge as thirdStageSecuredByPledgeRequirement}
				<CreditorRequirementRow requirement={thirdStageSecuredByPledgeRequirement} {openCreditorsRequirementModal} {openAmendementModal} {relevanceDate} />
			{/each}
		{:else}
			<tr class="no-data"><td class="text-secondary text-center" colspan={tableTitles.length}>Нет данных</td></tr>
		{/if}
		<SubTitleRow title="Третья очередь: требования, не обеспеченные залогом" sums={sums(thirdStageNotSecuredByPledge)} />
		{#if !mounted}
			<tr><td class="text-secondary text-center" colspan={tableTitles.length}><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
		{:else if isPresent(thirdStageNotSecuredByPledge)}
			{#each thirdStageNotSecuredByPledge as thirdStageNotSecuredByPledgeRequirement}
				<CreditorRequirementRow requirement={thirdStageNotSecuredByPledgeRequirement} {openCreditorsRequirementModal} {openAmendementModal} {relevanceDate} />
			{/each}
		{:else}
			<tr class="no-data"><td class="text-secondary text-center" colspan={tableTitles.length}>Нет данных</td></tr>
		{/if}
		<SubTitleRow title="Третья очередь: мораторные проценты" sums={sums(thirdStagePercents)} />
		{#if !mounted}
			<tr><td class="text-secondary text-center" colspan={tableTitles.length}><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
		{:else if isPresent(thirdStagePercents)}
			{#each thirdStagePercents as thirdStagePercentsRequirement}
				<CreditorRequirementRow requirement={thirdStagePercentsRequirement} {openCreditorsRequirementModal} {openAmendementModal} {relevanceDate} />
			{/each}
		{:else}
			<tr class="no-data"><td class="text-secondary text-center" colspan={tableTitles.length}>Нет данных</td></tr>
		{/if}
		<SubTitleRow title="Третья очередь: штрафы, пени" sums={sums(thirdStageFines)} />
		{#if !mounted}
			<tr><td class="text-secondary text-center" colspan={tableTitles.length}><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
		{:else if isPresent(thirdStageFines)}
			{#each thirdStageFines as thirdStageFinesRequirement}
				<CreditorRequirementRow requirement={thirdStageFinesRequirement} {openCreditorsRequirementModal} {openAmendementModal} {relevanceDate} />
			{/each}
		{:else}
			<tr class="no-data"><td class="text-secondary text-center" colspan={tableTitles.length}>Нет данных</td></tr>
		{/if}
		<SubTitleRow title="ИТОГО" sums={sums(allBesidesExcludedAndOffReestr)} />
		<SubTitleRow title="Справочно: требования, учитываемые за реестром" sums={sums(creditorsRequirementsOffReestr)} />
		{#if !mounted}
			<tr><td class="text-secondary text-center" colspan={tableTitles.length}><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
		{:else if isPresent(creditorsRequirementsOffReestr)}
			{#each creditorsRequirementsOffReestr as offReestrRequirement}
				<CreditorRequirementRow requirement={offReestrRequirement} {openCreditorsRequirementModal} {openAmendementModal} {relevanceDate} />
			{/each}
		{:else}
			<tr class="no-data"><td class="text-secondary text-center" colspan={tableTitles.length}>Нет данных</td></tr>
		{/if}
		<SubTitleRow title="ВСЕГО" sums={sums(allBesidesExcluded)} />
	</tbody>
</table>

<style>
	.creditors_requirements__table {
		width: 80%;
	}

	.thead-light > tr > th {
		border-top: 1px solid #cecece;
		border-bottom: 1px solid #cecece;
	}

	@media (max-width: 576px) {
		.creditors_requirements__table {
			width: 100%;
		}
	}
</style>
