<!-- @format -->
<script>
	import { procedure, transmitter } from '~/js/global_stores/base.js';
	import { onMount } from 'svelte';
	import Header from '~/svelte/components/header.svelte';
	import { isPresent, isBlank, toArray, formatDate } from '~/js/utils/tools.js';
	import { fetchGet } from '~/js/utils/fetch_helpers.js';

	const handleNew = kind => {
		fetchGet('/api/private/meetings/new', { kind }).then(result => {
			handleEdit(result.item.id);
		});
	};
	const handleEdit = id => {
		document.location.href = `/meetings/${id}/edit`;
	};
	let mounted = false;

	onMount(() => {
		if ($procedure && isPresent($procedure.id)) {
			fetchGet('/api/private/meetings').then(result => {
				$transmitter.meetings = [...result.rows];
				mounted = true;
			});
		}
	});

	$: worker_meetings = toArray($transmitter.meetings).filter(e => e.kind == 'worker');
	$: creditor_meetings = toArray($transmitter.meetings).filter(e => e.kind == 'creditor');
</script>

<Header><span slot="name">Собрания</span></Header>

{#each [{ kind: 'creditor', title: 'Собрания кредиторов', data: creditor_meetings }, { kind: 'worker', title: 'Собрания работников', data: worker_meetings }] as section}
	<h3 class="mb-2 ml-2 ml-sm-0">{section.title} ({section.data.length})</h3>
	<div class="m-b-lg mb-0 ml-2 ml-sm-0">
		<button class="btn btn-sm btn-warning m-r-sm m-b-sm" disabled={isBlank($procedure) || isBlank($procedure.id)} on:click={() => handleNew(section.kind)}>
			+ Собрание
		</button>
	</div>
	<div class="m-b-lg overlay p-1" class:footer-margin={section.title === 'Собрания работников'}>
		<table class="table table-hover table-mobile m-0 border-collapse_separate">
			<thead class="thead-light">
				<tr>
					<th class="text-center">Дата собрания</th>
					<th class="text-center">Вопросы повестки</th>
					<th class="text-center">Принятые решения</th>
					<th class="text-center">Публикация о проведении собрания</th>
					<th class="text-center">Публикация о принятых решениях</th>
					<th class="text-center">Статус</th>
				</tr>
			</thead>
			<tbody class="text-center">
				{#each section.data as r}
					<tr class="cursor_pointer" on:click={() => handleEdit(r.id)}>
						<td>
							<div>{formatDate(r.date_at)}</div>
							{#if r.creation_type == 'reconvened'}
								<div>(повторное)</div>
							{/if}
						</td>
						<td class="text-left">
							<ul>
								{#each r.meeting_questions.filter(e => e.question) as r, idx}
									<li>{`${idx + 1}. ${r.question}`}</li>
								{/each}
							</ul>
						</td>
						<td class="text-left">
							<ul>
								{#each r.meeting_questions.filter(e => e.solution) as r, idx}
									<li>{`${idx + 1}. ${r.solution}`}</li>
								{/each}
							</ul>
						</td>
						<td class="text-left"
							>{`${r.publication_about_number || ''}${r.publication_about_date ? `${r.publication_about_number ? ' от' : ''} ${formatDate(r.publication_about_date)}` : ''}`}</td
						>
						<td class="text-left"
							>{`${r.publication_solutions_number || ''}${r.publication_solutions_date ? `${r.publication_solutions_number ? ' от' : ''} ${formatDate(r.publication_solutions_date)}` : ''}`}</td
						>
						<td class="text-left">{r.status_text}</td>
					</tr>
				{/each}
				{#if mounted && !section.data.length}
					<tr class="text-secondary cursor_pointer">
						<td colspan="6">Нет данных</td>
					</tr>
				{/if}
				{#if !mounted}
					<tr><td class="text-secondary" colspan={6}><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
				{/if}
			</tbody>
		</table>
	</div>
{/each}

<style>
	ul {
		list-style-type: none;
		padding-left: 0;
	}
</style>
