<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { transmitter, options } from 'base_stores';
	import { fetchGet } from 'utils/fetch';
	import { isPresent, isBlank, isObject, toArray, deepEqual } from 'utils/tools';
	import InputDadataAddress from '~/svelte/components/ui/input_dadata_address.svelte';
	import InputDadataEmail from '~/svelte/components/ui/input_dadata_email.svelte';
	import Input from '~/svelte/components/ui/input.svelte';
	import InputDaDataParty from '~/svelte/components/ui/input_dadata_party.svelte';
	import searchBy from '~/svelte/components/ui/dadata/search_by.js';
	import InputErrorsString from '~/svelte/components/ui/input_errors_string.svelte';
	import Select2 from '~/svelte/components/ui/select2.svelte';
	import Checkbox from '~/svelte/components/ui/checkbox.svelte';
	import { counterparty } from './_stores';

	export let contractor;
	export let errors;
	export let individualFields;
	export let nameFieldFilled;
	export let disabled = false;

	let organization = {};
	let selectedOrganization;
	let mounted = false;
	let currentRoles = [];
	let nullOrganization = {
		id: null,
		short_name: null,
		full_name: null,
		inn: null,
		ogrn: null,
		legal_address_full: null,
		leader_full_name: null,
		phone: null,
		email: null,
		mailing_address_full: null,
		correspondent_kind: null,
		errors: {},
	};

	$: if ($counterparty.roles && mounted) {
		organization.correspondent_kind = $counterparty.roles.includes('cryptoex') ? 'cryptoex' : ($counterparty.roles.includes('bank') ? 'bank' : null)
	}

	const injectCountry = (address, country) => {
		const parts = address.split(',');
		const zipcode = parts.shift();
		parts.unshift(zipcode, ' ' + country);
		return parts.join(',');
	};

	const partySelected = ({ detail: data }) => {
		if (isObject(data)) {
			organization.full_name = (data.name && data.name.full_with_opf) || '';
			organization.inn = data.inn;
			organization.kpp = data.kpp;
			organization.ogrn = data.ogrn;
			organization.leader_full_name = (data.management && data.management.name) || '';
			organization.legal_address_full = injectCountry(data.address.unrestricted_value, data.address.data.country);
			organization.phone = toArray(data.phones)[0];
			organization.email = toArray(data.emails)[0];

			if ($counterparty.roles.includes('bank')) {
				searchBy('bank', organization.inn).then(result => {
					if (result.length) {
						const r = result[0];
						$counterparty.bank_bik = r.data.bic;
						$counterparty.bank_rkc = r.data.rkc;
						$counterparty.bank_cor_account = r.data.correspondent_account;
						$counterparty.bank_name = r.value;
					}
				});
			}
		} else {
			organization.short_name = data;
		}
	};

	$: individualFields = { organization };
	$: mailingAddressFull = contractor?.organization?.mailing_address_full;
	$: legalAddressFull = contractor?.organization?.legal_address_full;
	$: mailingAddressCheckValue = mailingAddressFull && legalAddressFull && mailingAddressFull === legalAddressFull;
	$: mailingAddressChecked = mailingAddressCheckValue;
	$: nameFieldFilled = organization.short_name;
	$: duplicatedCounterparty = $transmitter?.counterparties
		?.filter(c => c.contractor_type == 'Organization')
		?.find(c => {
			if (isBlank(organization.short_name)) { return false }

			return c.name.toLowerCase() === organization.short_name.toLowerCase() && c.id !== $counterparty.id;
		});

	$: hasDuplicatedCounterparty = isPresent(duplicatedCounterparty);

	$: if (mailingAddressChecked && organization) { organization.mailing_address_full = organization.legal_address_full }

	$: formFields = {
		id: {
			required: true,
			attribute: 'id',
			model: 'organization',
			label: 'Сокращенное наименование',
			placeholder: 'Выбирете криптобиржу',
			options: $options.organization,
			addOption: addOption,
			disabled: disabled || $counterparty.id,
		},
		shortName: {
			required: true,
			attribute: 'short_name',
			model: 'organization',
			label: 'Сокращенное наименование',
			placeholder: 'Введите наименование, ИНН, ОГРН или адрес',
			errors: errors?.short_name,
			disabled,
		},
		fullName: {
			attribute: 'full_name',
			model: 'organization',
			label: 'Полное наименование',
			placeholder: 'Общество с ограниченной ответственностью “Компания”',
			disabled,
		},
		inn: {
			attribute: 'inn',
			model: 'organization',
			label: 'ИНН',
			maskOptions: { mask: '000000000000' },
			placeholder: '123456789012',
			errors: errors?.inn,
			disabled,
		},
		kpp: {
			attribute: 'kpp',
			model: 'organization',
			label: 'КПП',
			maskOptions: { mask: '000000000' },
			placeholder: '123456789',
			errors: errors?.kpp,
			disabled,
		},
		ogrn: {
			attribute: 'ogrn',
			model: 'organization',
			label: 'ОГРН',
			maskOptions: { mask: '0000000000000' },
			placeholder: '1234567890123',
			errors: errors?.ogrn,
			disabled,
		},
		leaderFullName: {
			attribute: 'leader_full_name',
			model: 'organization',
			label: 'ФИО руководителя',
			placeholder: 'Фамилия И.О.',
			disabled,
		},
		phone: {
			attribute: 'phone',
			model: 'organization',
			label: 'Телефон',
			placeholder: '+7 (___) ___-__-__',
			maskOptions: { mask: '+7 (000) 000-00-00' },
			modify: phone => phone && phone.replace(/\+7|\D/g, ''),
			errors: errors?.phone,
			disabled,
		},
		email: {
			attribute: 'email',
			model: 'organization',
			label: 'Электронная почта',
			placeholder: 'mail@mail.ru',
			errors: errors?.email,
			disabled: disabled || $counterparty.id && !organization.user_id,
		},
		website: {
			attribute: 'website',
			model: 'organization',
			label: 'Сайт',
			placeholder: 'site.ru',
			errors: errors?.website,
			disabled: disabled || $counterparty.id && !organization.user_id,
		},
		legalAddressFull: {
			attribute: 'legal_address_full',
			model: 'organization',
			label: 'Юридический адрес',
			placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1',
			disabled,
		},
		mailingAddressFull: {
			disabled: mailingAddressChecked || disabled,
			attribute: 'mailing_address_full',
			model: 'organization',
			label: 'Почтовый адрес',
			placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1',
		},
		mailingAddressCheck: {
			text: 'тот же, что и юридический адрес',
			disabled,
		},
	};

	const refreshOrganization = (roles) => {
		if (JSON.stringify(currentRoles.sort()) === JSON.stringify(roles.sort())) { return }

		for (let role of ['bank', 'stockbroker', 'cryptoex']) {
			if (role == 'cryptoex' && !currentRoles.includes(role) && roles.includes(role)) { getOptions('cryptoex') }

			if (currentRoles.includes(role) && !roles.includes(role)) { organization = { ...nullOrganization } }
		}

		currentRoles = roles
	}

	$: refreshOrganization($counterparty.roles);

	const getOptions = async (kind) => {
		const response = await fetchGet('/api/private/organizations/options', { correspondent_kind: kind });
		$options.organization = [...response.options];
	};

	const addOption = (text) => {
		$options.organization = [{ ...nullOrganization, id: 'new', short_name: text, full_name: text, text }, ...$options.organization]
		return text
	};

	const setContractor = (id) => {
		const contractor = $options.organization?.find(o => o.id == id)

		if (contractor) {
			organization = { ...nullOrganization, ...contractor }
			nameFieldFilled = isPresent(organization.short_name)
		}
	}

	const observeOrgChanges = () => {
		if ($counterparty.roles.includes('cryptoex')) {
			const orgIdChanged = selectedOrganization?.id != organization.id

			if (orgIdChanged) {
				if ($options.organization?.find(o => o.text == organization.id)) {
					organization.id = 'new'
				} else {
					$options.organization = $options.organization?.filter(o => o.id != 'new')
				}

				setContractor(organization.id)
			} else {
				const orgChanged = !deepEqual(
					Object.fromEntries(Object.entries(organization).filter(([key]) => key != 'id')),
					Object.fromEntries(Object.entries(selectedOrganization || organization).filter(([key]) => key != 'id'))
				)

				if (!$counterparty.id && orgChanged) {
					if (!$options.organization.find(o => o.id == 'new')) { organization.text = `${organization.text} (изм.)` }

					$options.organization = [{ ...organization, id: 'new', text: organization.text }, ...$options.organization.filter(o => o.id != 'new')]
					setContractor('new')
				}
			}

			if (organization?.id != selectedOrganization?.id) { selectedOrganization = { ...organization } }
		}

		return organization
	}

	$: organization = observeOrgChanges(organization)

	onMount(() => {
		organization = contractor ? contractor.organization : { ...nullOrganization }
		currentRoles = $counterparty.roles

		if (currentRoles.includes('cryptoex')) { getOptions('cryptoex') }

		mounted = true
	})
</script>

<div>
	{#if $counterparty.roles.includes('cryptoex')}
		<Select2 {...formFields.id} bind:value={organization.id} />
		{#if hasDuplicatedCounterparty}
			<div class="form-group row has-error" style="margin-top: -1rem;">
				<div class="col-sm-4 col-lg-3 col-xl-4 col-form-label"></div>
				<div class="col-sm-8 col-lg-9 col-xl-8">
					<InputErrorsString errors={'Контрагент с таким наименованием уже существует'} />
				</div>
			</div>
		{/if}
	{:else}
		<InputDaDataParty {...formFields.shortName} bind:value={organization.short_name} on:change={partySelected} />
		{#if hasDuplicatedCounterparty}
			<div class="form-group row has-error" style="margin-top: -1rem;">
				<div class="col-sm-4 col-lg-3 col-xl-4 col-form-label"></div>
				<div class="col-sm-8 col-lg-9 col-xl-8">
					<InputErrorsString errors={'Контрагент с таким наименованием уже существует'} />
				</div>
			</div>
		{/if}
		<Input {...formFields.fullName} bind:value={organization.full_name} />
		<Input {...formFields.inn} bind:value={organization.inn} />
		<Input {...formFields.kpp} bind:value={organization.kpp} />
		<Input {...formFields.ogrn} bind:value={organization.ogrn} />
		<InputDadataAddress {...formFields.legalAddressFull} bind:value={organization.legal_address_full} />
		<InputDadataAddress {...formFields.mailingAddressFull} bind:value={organization.mailing_address_full} />
		<Checkbox {...formFields.mailingAddressCheck} bind:checked={mailingAddressChecked} />
		<Input {...formFields.leaderFullName} bind:value={organization.leader_full_name} />
		<Input {...formFields.phone} bind:value={organization.phone} />
	{/if}
	<InputDadataEmail {...formFields.email} bind:value={organization.email} />
	{#if $counterparty.roles.includes('cryptoex')}
		<Input {...formFields.website} bind:value={organization.website} />
	{/if}
</div>
