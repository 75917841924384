<!-- @format -->
<!-- @format -->
<script>
	import { onDestroy, afterUpdate, createEventDispatcher } from 'svelte';
	import { transmitter, autosaveStatus } from 'base_stores';
	import { coordinates, selectionСoordinates } from '~/js/global_stores/ocr';
	import printJS from 'print-js';
	import IconInfo from './icon-info.svelte';
	import DownloadIcon from './download_icon.svelte';
	import PrintIcon from './print_icon.svelte';
	import DestroyIcon from './destroy_icon.svelte';
	import { fetchPost, fetchPut, fetchGet, fetchDelete } from 'utils/fetch_helpers';
	import { addToFormData } from '~/svelte/components/ui/form/svelte-forms-lib';
	import { isPresent, isBlank } from 'utils/tools';
	import Modal, { showModal, hideModal } from '~/svelte/components/modal.svelte';

	export let scanName = '';
	export let isScanned = false;
	export let isRecognized = false;
	export let scanMessage = '';
	export let recognizeMessage = '';
	export let confirmRecognizeMessage = '';
	export let modelName = null;
	export let withPossessions = false;
	export let resultObserver;
	export let loading = false;
	export let autoRecognizeModalText = 'Автозаполнить';
	export let initRecognizeScan;
	export let getRecognizeResult;

	let files;
	let scan_id;
	let showInfo = false;
	let pagesTotal = 0;
	let pagesProcessed = 0;

	const initializeRecognizeMethods = () => {
		if (!initRecognizeScan) {
			initRecognizeScan = async () => {
				loading = true;
				$transmitter = { ...$transmitter, recognizeError: null };

				try {
					let params = { roi: $coordinates };
					if (modelName == 'answer') {
						params = { ...params, with_possessions: withPossessions };
					}

					const response = await fetchPut(`/api/private/${modelName}s/${model.id}/init_recognize_scan`, params);

					getRecognizeResult(response.task_id);
				} catch (error) {
					loading = false;
					console.error('Сервис распознавания временно не доступен по причине: ' + error);
					$transmitter = {
						...$transmitter,
						recognizeError: `Сервис распознавания временно не доступен по причине: ${error}`,
					};
				}
			};
		}
		if (!getRecognizeResult) {
			getRecognizeResult = taskId => {
				$autosaveStatus = null;

				let startTime = new Date();
				let params = { task_id: taskId };
				if (modelName == 'answer') {
					params = { ...params, with_possessions: withPossessions };
				}

				resultObserver = setInterval(async () => {
					try {
						const response = await fetchGet(`/api/private/${modelName}s/${model.id}/get_recognize_result`, params);

						if (!response.error && response.state == 'success') {
							let responseData = { [modelName]: response[modelName] };
							if (withPossessions) {
								responseData = { ...responseData, draftPossessions: response.draft_possessions || [] };
							}
							$transmitter = { ...$transmitter, ...responseData };
							$autosaveStatus = 'saved';

							interruptRecognition();
						}
						if (new Date() - startTime > 240000) {
							showModal('continue-recognize-scan-modal');
							startTime = new Date();
						}
					} catch (error) {
						console.error(error);
						$transmitter = {
							...$transmitter,
							recognizeError: `В процессе распознавания произошла ошибка: ${error}. Попробуйте ещё раз, затем обратитесь к администратору.`,
						};
						$autosaveStatus = 'not_saved';
						interruptRecognition();
					}
				}, 2000);
			};
		}
	};

	const interruptRecognition = () => {
		loading = false;
		clearInterval(resultObserver);
		hideModal('continue-recognize-scan-modal');
	};

	const dispatch = createEventDispatcher();

	$: model = $transmitter && $transmitter[modelName];
	$: $transmitter = { ...$transmitter, loading };

	$: info = () => {
		if (isPresent(model)) {
			if (!isScanned) {
				return scanMessage;
			} else if (!isRecognized) {
				return recognizeMessage;
			}
		} else {
			return '';
		}
	};

	const resetPageCounter = () => {
		pagesTotal = 0;
		pagesProcessed = 0;
	};

	const checkPagesProcessed = () => {
		const observer = setInterval(async () => {
			try {
				const response = await fetchGet(`/api/private/scans/${scan_id}/blobs_processed`);
				pagesProcessed = response.pages_processed;

				if (allPagesProcessed) {
					resetPageCounter();
					$autosaveStatus = 'saved';
					clearInterval(observer);
				}
			} catch (error) {
				console.error(error);
				resetPageCounter();
				clearInterval(observer);
				$transmitter = { ...$transmitter, recognizeError: error };
				throw error;
			}
		}, 2000);
	};

	const getScanBlobs = () => {
		const observer = setInterval(async () => {
			try {
				const response = await fetchGet(`/api/private/scans/${scan_id}/blob_paths`, { offset: 0 });
				pagesTotal = response.pages_total;

				if (isPresent(response.scan)) {
					$transmitter = { ...$transmitter, [modelName]: { ...model, scan: response.scan } };
					clearInterval(observer);
					pagesProcessed = response.scan.length;
					checkPagesProcessed();
				}
			} catch (error) {
				console.error(error);
				resetPageCounter();
				$autosaveStatus = 'not_saved';
				clearInterval(observer);
				$transmitter = { ...$transmitter, [modelName]: { ...model, scan: [], scan_id: null }, recognizeError: error };
				throw error;
			}
		}, 2000);
	};

	$: uploadFile(files);

	$: allPagesProcessed = pagesProcessed == pagesTotal;

	const uploadFile = async files => {
		if (isBlank(files)) {
			return;
		}

		$transmitter = { ...$transmitter, recognizeError: null };
		pagesTotal = 1;
		$autosaveStatus = null;
		$selectionСoordinates = [];
		const formData = new FormData();
		const scanType = files[0].type === 'application/pdf' ? 'file_pdf' : 'file_jpg';

		addToFormData(formData, 'scan', { [scanType]: scanType == 'file_pdf' ? files[0] : files, [`${modelName}_id`]: model.id });

		try {
			let result = await fetchPost(`/api/private/scans`, formData);
			scan_id = result.scan_id;
			$transmitter = { ...$transmitter, [modelName]: { ...model, scan: result.scan, scan_id: result.scan_id } };

			getScanBlobs();
		} catch (error) {
			console.error(error);
			resetPageCounter();
			$autosaveStatus = 'not_saved';
			throw error;
		}
	};

	const deleteScan = async () => {
		$selectionСoordinates = [];
		await fetchDelete(`/api/private/scans/${model.scan_id}`);
		resetPageCounter();
		$transmitter = { ...$transmitter, [modelName]: { ...model, scan: [], scan_id: null } };
	};

	onDestroy(() => interruptRecognition());

	afterUpdate(() => {
		initializeRecognizeMethods();
	});
</script>

<div class="row">
	<div class="col-12">
		<ul class="nav float-left">
			<li>
				{#if isBlank($transmitter[modelName].scan) || (pagesTotal > 0 && !allPagesProcessed)}
					{#if model?.id}
						{#if pagesTotal > 0 && !allPagesProcessed}
							<span class="action-button btn btn-xs btn-warning m-r-sm cursor_pointer disabled">
								{`+ ${scanName}`}
								<i class="fa fa-spinner fa-pulse fa-lg m-l-xs" />
							</span>
						{:else if isBlank($transmitter[modelName].scan)}
							<label class="file-label">
								<input bind:files accept="image/png, image/jpeg, application/pdf" multiple="true" class="file-input" type="file" />
								<span class="action-button btn btn-xs btn-warning m-r-sm cursor_pointer">
									{`+ ${scanName}`}
								</span>
							</label>
						{/if}
					{:else}
						<span class="action-button btn btn-xs btn-warning m-r-sm cursor_pointer disabled">
							{`+ ${scanName}`}
						</span>
					{/if}
				{:else if (pagesTotal > 0 && allPagesProcessed) || isPresent($transmitter[modelName].scan)}
					<button
						class="action-button btn btn-xs btn-primary m-r-sm cursor_pointer"
						class:cursor_pointer={!isRecognized}
						class:recognized={isRecognized}
						class:disabled={loading}
						on:click={!isRecognized &&
							(() => {
								dispatch('recognizeScan');
								showModal('recognize-scan-modal');
							})}
					>
						{loading ? 'Распознавание...' : !isRecognized ? 'Распознать' : 'Распознано'}
						{#if loading}
							<i class="fa fa-spinner fa-pulse fa-lg m-l-xs" />
						{/if}
					</button>
				{/if}
			</li>
			{#if isPresent(info())}
				<li class="mr-4">
					<div
						class="my-popover m-r-sm"
						role="button"
						tabindex="0"
						on:mouseover={() => (showInfo = true)}
						on:mouseout={() => (showInfo = false)}
						on:focus={() => (showInfo = true)}
						on:blur={() => (showInfo = false)}
					>
						<IconInfo />
						{#if showInfo}
							<div class="raf__tooltip show fade">{info()}</div>
						{/if}
					</div>
				</li>
			{/if}
			<li class="mt-1">
				<a
					download
					class:disabled={(isPresent(model) && isBlank(model.scan_id)) || loading || (pagesTotal > 0 && !allPagesProcessed)}
					target="_self"
					href={`${model.id}/download_scan`}
				>
					<DownloadIcon />
				</a>
			</li>
			<li class="ml-3 mt-1">
				<span
					class="cursor_pointer"
					class:disabled={(isPresent(model) && isBlank(model.scan_id)) || loading || (pagesTotal > 0 && !allPagesProcessed)}
					role="button"
					tabindex="0"
					on:click={() => printJS({ printable: model.scan, type: 'image', showModal: true, modalMessage: 'Подготовка документа...' })}
					on:keypress|stopPropagation
				>
					<PrintIcon />
				</span>
			</li>
			<li class="ml-3 mt-1">
				<span
					class="cursor_pointer"
					class:disabled={(isPresent(model) && isBlank(model.scan_id)) || loading || (pagesTotal > 0 && !allPagesProcessed)}
					role="button"
					tabindex="0"
					on:click={() => {
						dispatch('deleteScan');
						showModal('delete-scan-modal');
					}}
					on:keypress|stopPropagation
				>
					<DestroyIcon />
				</span>
			</li>
		</ul>
		{#if pagesTotal > 0 && !allPagesProcessed}
			<div class="progress" style="--progress: {pagesProcessed / (pagesTotal > 0 ? pagesTotal : 1)}"></div>
		{/if}
	</div>
</div>
<Modal
	modalId="continue-recognize-scan-modal"
	cancelButtonText="Продолжить"
	cancelButtonCss="btn-primary"
	submitButtonText="Прервать"
	submitButtonCss="btn-danger"
	submitButtonAction={interruptRecognition}
>
	<h3 slot="header" class="m-r-md">
		<p>Распознавание продолжается дольше обычного.</p>
		<p>Вы можете прервать операцию.</p>
	</h3>
</Modal>
<Modal modalId="recognize-scan-modal" submitButtonText={autoRecognizeModalText} submitButtonAction={initRecognizeScan}>
	<h3 slot="header" class="m-r-md">{confirmRecognizeMessage}</h3>
</Modal>
<Modal modalId="delete-scan-modal" submitButtonText="Удалить" submitButtonCss="btn-danger" submitButtonAction={deleteScan}>
	<h3 slot="header">Удалить прикрепленный файл?</h3>
</Modal>

<style lang="scss">
	.nav > li > a {
		padding: 0 !important;
		min-height: 0 !important;
	}
	.file-label {
		cursor: pointer;
		overflow: hidden;
		position: relative;
		margin-bottom: -6px;
	}
	.file-input {
		left: 0;
		opacity: 0;
		outline: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
	.my-popover {
		margin-top: 2px;
		position: relative;
		.show {
			position: absolute;
			z-index: 10010;
			background-color: #fff;
			border: solid 1px;
			border-radius: 0.3rem;
			box-shadow: 0 0 10px;
			display: block;
			padding: 0.7rem 1rem;
			top: 30px;
			width: 300px;
		}
	}
	.recognized {
		color: #ddd;
		background-color: #1ab394;
		border-color: #1ab394;
	}
	.progress {
		width: calc(100% * var(--progress) - 30px * var(--progress));
		height: 5px;
		background: #f8ac59;
		position: absolute;
		top: 30px;
	}
</style>
