/** @format */

import { writable, derived } from 'svelte/store';
import { procedure, transmitter, options, dctReady, dctDisabled, newObject, changedFields, bankruptIsPerson } from 'base_stores';
import { procedureAvailable } from '~/svelte/components/payment/stores';
import { isPresent, isBlank } from 'utils/tools';
import moment from 'moment';

export const dcts = derived([transmitter], ([$transmitter]) => $transmitter?.dcts);
export const tags = derived([transmitter], ([$transmitter]) => $transmitter?.tags);
//export const dct = derived([transmitter, dct], ([$transmitter, $dct]) => ($transmitter?.dct || $dct))
export const dct = derived([transmitter], ([$transmitter]) => $transmitter?.dct);
export const dctId = derived(dct, $dct => $dct?.id);
export const substitutions = derived(dct, $dct => $dct?.substitutions);

export const reloadRunningCosts = writable(false);

export const dctFormAvailable = derived([procedureAvailable, dct], ([$procedureAvailable, $dct]) =>
	isBlank($dct) ? false : $procedureAvailable && $dct.status === 'draft',
);

export const bankAccounts = derived([transmitter, substitutions], ([$transmitter, $substitutions]) => {
	const bankName = $substitutions['correspondent_short_name'];
	const bankAccounts = $transmitter && ($transmitter.bankAccounts || $transmitter.bank_accounts);
	const mainBankAccounts = bankAccounts && bankAccounts.filter(bankAccount => bankAccount.is_main);
	const allBankAccounts = (bankAccounts && bankAccounts.filter(bankAccount => bankAccount.counterparty_name === bankName)) || [];
	const otherBankAccounts = allBankAccounts.filter(bankAccount => !bankAccount.is_main) || [];

	return { mainBankAccounts, allBankAccounts, otherBankAccounts };
});

// поменялся шаблон запроса в росреестр. т.к. нет версионности шаблонов,
// необходимо как-то отличать доки со старым шаблоном от доков с новым шаблоном
export const isOldRosreestrRequest = derived(
	dct,
	$dct =>
		$dct &&
		$dct.category === 'outgoing' &&
		$dct.kind === 'request' &&
		$dct.correspondent_kind === 'rosreestr' &&
		$dct.status === 'approved' &&
		moment($dct.updated_at).isBefore(moment('2022-02-11T00:00:00.000Z')),
);

export const dctStatus = writable(null);
export const pdfDctData = writable(null);
export const requiredFieldsFilled = writable({});
export const creditorsRequirementsGroups = writable(null);

export const commonParams = derived([procedure, dct, dctReady, dctDisabled], ([$procedure, $dct, $dctReady, $dctDisabled]) => ({
	url: '/api/private/documents',
	model: 'dct',
	id: $dct?.id,
	childModel: 'procedure',
	childId: $procedure?.id,
	additionalParams: { category: $dct?.category },
	saveAutomatically: $dct?.status !== 'approved',
	disabled: $dctDisabled || !$dctReady || $dct?.status === 'approved',
}));

export const creditorsRequirements = derived([transmitter, dct, bankruptIsPerson], ([$transmitter, $dct, $bankruptIsPerson]) => {
	const finesKinds = ['Проценты за просрочку платежа', 'Пени', 'Штраф', 'Проценты за пользование чужими денежными средствами (по ст.395 ГК)', 'Неустойка'];

	let creditorsRequirements = $transmitter?.creditors_requirements || [];
	if ($bankruptIsPerson) {
		creditorsRequirements = creditorsRequirements.map(c => ({
			...c,
			counterparty_name_inn: `${c.counterparty_name}${c.counterparty_inn ? `, ИНН ${c.counterparty_inn}` : ''}`,
		}));
	}

	const numberCounterparties = [...new Set(creditorsRequirements.map(requirement => requirement.counterparty_id))].length;
	const relevanceDate = $dct?.requirements_reestr_closing_date;

	let creditorsRequirementsInReestr = creditorsRequirements?.filter(requirement => !requirement.off_reestr);
	let creditorsRequirementsOffReestr = creditorsRequirements?.filter(requirement => requirement.off_reestr);

	if (creditorsRequirementsInReestr) {
		const counterpartyIds = [...new Set(creditorsRequirementsInReestr.map(requirement => requirement.counterparty_id))];
		creditorsRequirementsInReestr.forEach((requirement, index) => {
			requirement.index = index + 1;
			requirement.counterparty_number = counterpartyIds.indexOf(requirement.counterparty_id) + 1;
		});
		creditorsRequirementsInReestr = [...creditorsRequirementsInReestr];
	}

	if (creditorsRequirementsOffReestr) {
		const counterpartyIds = [...new Set(creditorsRequirementsOffReestr.map(requirement => requirement.counterparty_id))];
		creditorsRequirementsOffReestr.forEach((requirement, index) => {
			requirement.index = index + 1;
			requirement.counterparty_number = counterpartyIds.indexOf(requirement.counterparty_id) + 1;
		});
		creditorsRequirementsOffReestr = [...creditorsRequirementsOffReestr];
	}

	// требования первой очереди
	const firstStage = creditorsRequirementsInReestr?.filter(requirement => parseInt(requirement.order_of_repayment) === 1);
	// требования второй очереди
	const secondStage = creditorsRequirementsInReestr?.filter(requirement => parseInt(requirement.order_of_repayment) === 2);
	// требования третьей очереди ВСЕ
	const thirdStage = creditorsRequirementsInReestr?.filter(requirement => parseInt(requirement.order_of_repayment) === 3);
	// требования третьей очереди обеспеченные залогом, исключая пени и штрафы
	const thirdStageSecuredByPledge = thirdStage?.filter(requirement => requirement.secured_by_pledge && !finesKinds.includes(requirement.kind));
	// требования третьей очереди не обеспеченные залогом, исключая пени и штрафы
	const thirdStageNotSecuredByPledge = thirdStage?.filter(requirement => !requirement.secured_by_pledge && !finesKinds.includes(requirement.kind));
	// проценты
	const thirdStagePercents = [];
	// пени, штрафы
	const thirdStageFines = thirdStage?.filter(requirement => finesKinds.includes(requirement.kind));
	// требования, кроме исключённых и учитываемых за реестром
	const allBesidesExcludedAndOffReestr = creditorsRequirementsInReestr?.filter(
		requirement => !requirement.excluded_date || requirement.excluded_date > relevanceDate,
	);
	// требования, кроме исключённых
	const allBesidesExcluded =
		creditorsRequirementsInReestr &&
		creditorsRequirementsOffReestr &&
		creditorsRequirementsInReestr
			.concat(creditorsRequirementsOffReestr)
			.filter(requirement => !requirement.excluded_date || requirement.excluded_date > relevanceDate);

	return {
		firstStage,
		secondStage,
		thirdStage,
		thirdStageSecuredByPledge,
		thirdStageNotSecuredByPledge,
		thirdStagePercents,
		thirdStageFines,
		creditorsRequirementsInReestr,
		creditorsRequirementsOffReestr,
		allBesidesExcludedAndOffReestr,
		allBesidesExcluded,
		numberCreditorsRequirements: creditorsRequirements.length,
		numberCounterparties,
	};
});

export const allPossessions = derived([transmitter, options], ([$transmitter, $options]) => {
	const bankAccounts = $transmitter?.bankAccounts || [];
	const receivables = $transmitter?.receivables || [];
	let other = $transmitter?.possessions || [];

	const dct = $transmitter?.dct;
	if (isPresent(dct) && dct.category != 'reestrs' && dct.kind != 'possessions') {
		other = other.map(item => ({ ...item, name: item.name_ext }));
	}

	const possessionKinds = $options?.possessionKind || [];
	let kindsCodesIds = {};
	for (let kind of possessionKinds) {
		kindsCodesIds[kind.code] = kind.value;
	}

	const possessionsSubjectsLand = other.filter(possession => possession.kind_id == kindsCodesIds['land']);

	const possessionsSubjectsResidential = other.filter(possession => possession.parent_kind_id == kindsCodesIds['residential']);
	const possessionsSubjectsResidentialHouses = other.filter(possession => possession.kind_id == kindsCodesIds['residential_houses']);
	const possessionsSubjectsApartments = other.filter(possession => possession.kind_id == kindsCodesIds['apartments']);

	const possessionsSubjectsNonResidential = other.filter(possession => possession.parent_kind_id == kindsCodesIds['non_residential']);
	const possessionsSubjectsGarages = other.filter(possession => possession.kind_id == kindsCodesIds['garages']);
	const possessionsSubjectsOtherRealEstate = other.filter(possession => possession.kind_id == kindsCodesIds['other_real_estate']);

	const possessionsSubjectsTransport = other.filter(possession => possession.parent_kind_id == kindsCodesIds['transport']);
	const possessionsSubjectsCars = other.filter(possession => possession.kind_id == kindsCodesIds['cars']);
	const possessionsSubjectsTrucks = other.filter(possession => possession.kind_id == kindsCodesIds['trucks']);
	const possessionsSubjectsMotorVehicles = other.filter(possession => possession.kind_id == kindsCodesIds['motor_vehicles']);
	const possessionsSubjectsAgriculturalMachinery = other.filter(possession => possession.kind_id == kindsCodesIds['agricultural_machinery']);
	const possessionsSubjectsWaterTransport = other.filter(possession => possession.kind_id == kindsCodesIds['water_transport']);
	const possessionsSubjectsAirTransport = other.filter(possession => possession.kind_id == kindsCodesIds['air_transport']);
	const possessionsSubjectsOtherVehicles = other.filter(possession => possession.kind_id == kindsCodesIds['other_vehicles']);

	let possessionsSubjectsShare = [
		...other.filter(possession => [kindsCodesIds['stock']].includes(possession.kind_id)),
		...other.filter(possession => [kindsCodesIds['shares_in_commercial_organizations']].includes(possession.kind_id)),
	];

	if (isBlank(possessionsSubjectsShare)) {
		possessionsSubjectsShare = other.filter(possession => [kindsCodesIds['share']].includes(possession.kind_id));
	}

	const possessionsSubjectsStock = other.filter(possession => possession.kind_id == kindsCodesIds['stock']);
	const possessionsSubjectsSharesInCommercialOrganizations = other.filter(
		possession => possession.kind_id == kindsCodesIds['shares_in_commercial_organizations'],
	);

	const possessionsSubjectsOther = other.filter(possession => possession.parent_kind_id == kindsCodesIds['other']);
	const possessionsSubjectsWeapon = other.filter(possession => possession.kind_id == kindsCodesIds['weapon']);
	const possessionsSubjectsSecurities = other.filter(possession => possession.kind_id == kindsCodesIds['securities']);
	const possessionsSubjectsJewelry = other.filter(possession => possession.kind_id == kindsCodesIds['jewelry']);
	const possessionsSubjectsArtObjects = other.filter(possession => possession.kind_id == kindsCodesIds['art_objects']);
	const possessionsSubjectsProfessionalProperty = other.filter(possession => possession.kind_id == kindsCodesIds['professional_property']);
	const possessionsSubjectsIntellectualProperty = other.filter(possession => possession.kind_id == kindsCodesIds['intellectual_property']);
	const possessionsSubjectsOtherValuableProperty = other.filter(
		possession => possession.kind_id == kindsCodesIds['other_valuable_property'] || possession.kind_id == kindsCodesIds['weapon'],
	);

	const possessionsNoPledgeSubjectsLand = other.filter(possession => !possession.is_pledge_subject && possession.kind_id == kindsCodesIds['land']);
	const possessionsNoPledgeSubjectsResidential = other.filter(
		possession => !possession.is_pledge_subject && possession.parent_kind_id == kindsCodesIds['residential'],
	);
	const possessionsNoPledgeSubjectsNonResidential = other.filter(
		possession => !possession.is_pledge_subject && possession.parent_kind_id == kindsCodesIds['non_residential'],
	);
	const possessionsNoPledgeSubjectsTransport = other.filter(
		possession => !possession.is_pledge_subject && possession.parent_kind_id == kindsCodesIds['transport'],
	);
	const possessionsNoPledgeSubjectsShare = other.filter(possession => !possession.is_pledge_subject && possession.parent_kind_id == kindsCodesIds['share']);
	const possessionsNoPledgeSubjectsOther = other.filter(possession => !possession.is_pledge_subject && possession.parent_kind_id == kindsCodesIds['other']);
	const possessionsBankAccounts = bankAccounts;
	const possessionsReceivables = receivables.map(c => ({
		...c,
		pledgee: c.pledgee.join(';<br/>'),
		counterparty_name_inn: `${c.counterparty_name}${c.counterparty_inn ? `, ИНН ${c.counterparty_inn}` : ''}`,
	}));
	const possessionsPledgeSubjectsLand = other.filter(possession => possession.is_pledge_subject && possession.kind_id == kindsCodesIds['land']);
	const possessionsPledgeSubjectsResidential = other.filter(
		possession => possession.is_pledge_subject && possession.parent_kind_id == kindsCodesIds['residential'],
	);
	const possessionsPledgeSubjectsNonResidential = other.filter(
		possession => possession.is_pledge_subject && possession.parent_kind_id == kindsCodesIds['non_residential'],
	);
	const possessionsPledgeSubjectsTransport = other.filter(
		possession => possession.is_pledge_subject && possession.parent_kind_id == kindsCodesIds['transport'],
	);
	const possessionsPledgeSubjectsShare = other.filter(possession => possession.is_pledge_subject && possession.parent_kind_id == kindsCodesIds['share']);
	const possessionsPledgeSubjectsOther = other.filter(possession => possession.is_pledge_subject && possession.parent_kind_id == kindsCodesIds['other']);

	const calculateAmount = (source, amountParam) => {
		if (!source) {
			return [0, 0, 0, 0];
		} else if (amountParam) {
			return [source?.reduce((totalAmount, item) => totalAmount + (item[amountParam] ? parseFloat(item[amountParam]) : 0), 0), 0, 0, 0];
		} else {
			return [
				calculateItemAmount(source, false, 'arbitr_manager'),
				calculateItemAmount(source, false, 'appraiser'),
				calculateItemAmount(source, true, 'arbitr_manager'),
				calculateItemAmount(source, true, 'appraiser'),
			];
		}
	};

	const calculateItemAmount = (source, is_excluded_from_bankruptcy, valuer) => {
		return source
			?.filter(possession => !!possession.excluded_from_bankruptcy === is_excluded_from_bankruptcy && possession.valuer == valuer)
			?.reduce((totalAmount, possession) => totalAmount + (possession.cost ? parseFloat(possession.cost) : 0), 0);
	};

	return {
		possessionsSubjectsLand,
		possessionsSubjectsLandAmount: calculateAmount(possessionsSubjectsLand),

		possessionsSubjectsResidential,
		possessionsSubjectsResidentialAmount: calculateAmount(possessionsSubjectsResidential),
		possessionsSubjectsResidentialHouses,
		possessionsSubjectsApartments,

		possessionsSubjectsNonResidential,
		possessionsSubjectsNonResidentialAmount: calculateAmount(possessionsSubjectsNonResidential),
		possessionsSubjectsGarages,
		possessionsSubjectsOtherRealEstate,

		possessionsSubjectsTransport,
		possessionsSubjectsTransportAmount: calculateAmount(possessionsSubjectsTransport),
		possessionsSubjectsCars,
		possessionsSubjectsTrucks,
		possessionsSubjectsMotorVehicles,
		possessionsSubjectsAgriculturalMachinery,
		possessionsSubjectsWaterTransport,
		possessionsSubjectsAirTransport,
		possessionsSubjectsOtherVehicles,

		possessionsSubjectsShare,
		possessionsSubjectsShareAmount: calculateAmount(possessionsSubjectsShare),
		possessionsSubjectsStock,
		possessionsSubjectsSharesInCommercialOrganizations,

		possessionsSubjectsOther,
		possessionsSubjectsOtherAmount: calculateAmount(possessionsSubjectsOther),
		possessionsSubjectsWeapon,
		possessionsSubjectsSecurities,
		possessionsSubjectsJewelry,
		possessionsSubjectsArtObjects,
		possessionsSubjectsProfessionalProperty,
		possessionsSubjectsIntellectualProperty,
		possessionsSubjectsOtherValuableProperty,

		possessionsGenericSubjectsLand: possessionsSubjectsLand,
		possessionsGenericSubjectsLandAmount: calculateAmount(possessionsSubjectsLand),
		possessionsGenericSubjectsResidential: possessionsSubjectsResidential,
		possessionsGenericSubjectsResidentialAmount: calculateAmount(possessionsSubjectsResidential),
		possessionsGenericSubjectsNonResidential: possessionsSubjectsNonResidential,
		possessionsGenericSubjectsNonResidentialAmount: calculateAmount(possessionsSubjectsNonResidential),
		possessionsGenericSubjectsTransport: possessionsSubjectsTransport,
		possessionsGenericSubjectsTransportAmount: calculateAmount(possessionsSubjectsTransport),
		possessionsGenericSubjectsShare: possessionsSubjectsShare,
		possessionsGenericSubjectsShareAmount: calculateAmount(possessionsSubjectsShare),
		possessionsGenericSubjectsOther: possessionsSubjectsOther,
		possessionsGenericSubjectsOtherAmount: calculateAmount(possessionsSubjectsOther),

		possessionsNoPledgeSubjectsLand,
		possessionsNoPledgeSubjectsLandAmount: calculateAmount(possessionsNoPledgeSubjectsLand),
		possessionsNoPledgeSubjectsResidential,
		possessionsNoPledgeSubjectsResidentialAmount: calculateAmount(possessionsNoPledgeSubjectsResidential),
		possessionsNoPledgeSubjectsNonResidential,
		possessionsNoPledgeSubjectsNonResidentialAmount: calculateAmount(possessionsNoPledgeSubjectsNonResidential),
		possessionsNoPledgeSubjectsTransport,
		possessionsNoPledgeSubjectsTransportAmount: calculateAmount(possessionsNoPledgeSubjectsTransport),
		possessionsNoPledgeSubjectsShare,
		possessionsNoPledgeSubjectsShareAmount: calculateAmount(possessionsNoPledgeSubjectsShare),
		possessionsNoPledgeSubjectsOther,
		possessionsNoPledgeSubjectsOtherAmount: calculateAmount(possessionsNoPledgeSubjectsOther),
		possessionsBankAccounts,
		possessionsBankAccountsAmount: calculateAmount(possessionsBankAccounts, 'balance_rub'),
		possessionsReceivables,
		possessionsReceivablesAmount: calculateAmount(possessionsReceivables, 'debt_amount'),

		possessionsPledgeSubjectsLand,
		possessionsPledgeSubjectsLandAmount: calculateAmount(possessionsPledgeSubjectsLand),
		possessionsPledgeSubjectsResidential,
		possessionsPledgeSubjectsResidentialAmount: calculateAmount(possessionsPledgeSubjectsResidential),
		possessionsPledgeSubjectsNonResidential,
		possessionsPledgeSubjectsNonResidentialAmount: calculateAmount(possessionsPledgeSubjectsNonResidential),
		possessionsPledgeSubjectsTransport,
		possessionsPledgeSubjectsTransportAmount: calculateAmount(possessionsPledgeSubjectsTransport),
		possessionsPledgeSubjectsShare,
		possessionsPledgeSubjectsShareAmount: calculateAmount(possessionsPledgeSubjectsShare),
		possessionsPledgeSubjectsOther,
		possessionsPledgeSubjectsOtherAmount: calculateAmount(possessionsPledgeSubjectsOther),

		bankAccounts,
		receivables,
		other,
	};
});

export const allOrganizationPossessions = derived([transmitter, options], ([$transmitter, $options]) => {
	const bankAccounts = $transmitter?.bankAccounts || [];
	const receivables = $transmitter?.receivables || [];
	const other = $transmitter?.possessions || [];

	const possessionKinds = ($options && $options.possessionKind) || [];
	let kindsCodesIds = {};
	for (let kind of possessionKinds) {
		kindsCodesIds[kind.code] = kind.value;
	}

	const groupedPossessions = {
		residentialBuildings: other?.filter(possession => possession.kind_id === kindsCodesIds['residential_buildings']),
		sociallySignificantBuildings: other?.filter(possession => possession.kind_id === kindsCodesIds['socially_significant_buildings']),
		otherBuildings: other?.filter(possession => possession.kind_id === kindsCodesIds['other_buildings']),
		sociallySignificantConstructions: other?.filter(possession => possession.kind_id === kindsCodesIds['socially_significant_constructions']),
		otherConstructions: other?.filter(possession => possession.kind_id === kindsCodesIds['other_constructions']),
		vehicles: other?.filter(possession => possession.parent_kind_id === kindsCodesIds['vehicles']),
		machinery: other?.filter(possession => possession.parent_kind_id === kindsCodesIds['machinery']),
		otherImmovables: other?.filter(possession => possession.kind_id === kindsCodesIds['other_immovables']),
		landPlot: other?.filter(possession => possession.kind_id === kindsCodesIds['land_plot']),
		isolatedRoom: other?.filter(possession => possession.kind_id === kindsCodesIds['isolated_room']),
		intangibleAssets: other?.filter(possession => possession.kind_id === kindsCodesIds['intangible_assets']),
		constructionInProgress: other?.filter(possession => possession.kind_id === kindsCodesIds['construction_in_progress']),
		longTermFinancialInvestments: other?.filter(possession => possession.kind_id === kindsCodesIds['share']),
		otherNonCurrentFunds: other?.filter(possession => possession.kind_id === kindsCodesIds['other_non_current_funds']),
		unfinishedProduction: other?.filter(possession => possession.kind_id === kindsCodesIds['unfinished_production']),
		finishedProducts: other?.filter(possession => possession.kind_id === kindsCodesIds['finished_products']),
		money: bankAccounts.map(account => ({ cost: account.balance_rub, excluded_from_bankruptcy: false })),
		nds: other?.filter(possession => possession.kind_id === kindsCodesIds['nds']),
		debit: receivables
			.filter(receivable => receivable.kind === 'receivables')
			.map(receivable => ({
				cost: receivable.debt_amount,
				excluded_from_bankruptcy: receivable.excluded_from_bankruptcy,
				market_cost: receivable.cost,
			})),
		shortTermFinancialInvestments: receivables
			.filter(receivable => receivable.kind === 'short_term_financial_investments')
			.map(receivable => ({
				cost: receivable.debt_amount,
				excluded_from_bankruptcy: receivable.excluded_from_bankruptcy,
				market_cost: receivable.cost,
			})),
		//other?.filter(possession => possession.kind_id === kindsCodesIds['short_term_financial_investments']),
		otherCurrentFunds: other?.filter(possession => possession.kind_id === kindsCodesIds['other_current_funds']),
		pledgedProperty: other?.filter(possession => possession.is_pledge_subject),
	};

	const calculateAmount = source => {
		if (!source) {
			return [0, 0, 0, 0];
		} else {
			return [
				calculateItemAmount(source, false, 'cost'),
				calculateItemAmount(source, false, 'market_cost'),
				calculateItemAmount(source, true, 'cost'),
				calculateItemAmount(source, true, 'market_cost'),
			];
		}
	};

	const calculateItemAmount = (source, excluded_from_bankruptcy, costType) => {
		return source
			?.filter(possession => !!possession.excluded_from_bankruptcy === excluded_from_bankruptcy)
			?.reduce((totalAmount, possession) => totalAmount + (possession[costType] ? parseFloat(possession[costType]) : 0), 0);
	};

	const elementByElementSum = kinds => {
		let amount = [0, 0, 0, 0];

		for (let i = 0; i < 4; i++) {
			kinds.forEach(kind => (amount[i] += outPossessions[kind][i]));
		}

		return amount;
	};

	let outPossessions = {};

	for (let key of Object.keys(groupedPossessions)) {
		outPossessions[key] = calculateAmount(groupedPossessions[key]);
	}

	outPossessions['buildings'] = elementByElementSum(['residentialBuildings', 'sociallySignificantBuildings', 'otherBuildings']);
	outPossessions['constructions'] = elementByElementSum(['sociallySignificantConstructions', 'otherConstructions']);
	outPossessions['fixedAssets'] = elementByElementSum(['buildings', 'constructions', 'vehicles', 'machinery', 'otherImmovables', 'landPlot', 'isolatedRoom']);
	outPossessions['nonCurrentFunds'] = elementByElementSum([
		'fixedAssets',
		'intangibleAssets',
		'constructionInProgress',
		'longTermFinancialInvestments',
		'otherNonCurrentFunds',
	]);
	outPossessions['stocks'] = elementByElementSum(['unfinishedProduction', 'finishedProducts']);
	outPossessions['currentFunds'] = elementByElementSum(['stocks', 'money', 'nds', 'debit', 'shortTermFinancialInvestments', 'otherCurrentFunds']);
	outPossessions['allProperty'] = elementByElementSum(['nonCurrentFunds', 'currentFunds']);

	return outPossessions;
});

export const actualMeasuresOptions = derived([options], ([$options]) => {
	//const measureKindIndexes = { Person: { debt_restructuring: [1], property_realization: [0] } };
	const measureKinds = ($options && $options.measureKind) || [];

	return measureKinds;

	/*if (!measureKindIndexes[$bankruptType] || !(measureKindIndexes[$bankruptType] && measureKindIndexes[$bankruptType][$procedure.phase])) {
      return measureKinds || []
    }

    return (($options && measureKindIndexes) ?
      (measureKinds?.filter((_option, index) => (measureKindIndexes[$bankruptType][$procedure.phase].includes(index)))) :
      measureKinds) || []
    */
});

export const actualMeasures = derived([actualMeasuresOptions, transmitter], ([$actualMeasuresOptions, $transmitter]) => {
	const groupedMeasures = ($transmitter && $transmitter.measures) || groupedMeasures || [];
	const measureKindIds = $actualMeasuresOptions.map(option => option.id);

	return groupedMeasures && measureKindIds
		? measureKindIds
				.map(id => groupedMeasures[id])
				.filter(i => i)
				.flat(1)
		: Object.values(groupedMeasures || []).flat(1);
});

export const currentData = derived(
	[procedure, dct, creditorsRequirements, substitutions, options, transmitter, newObject, changedFields],
	([$procedure, $dct, $creditorsRequirements, $substitutions, $options, $transmitter, $newObject, $changedFields]) => {
		const procedure = $transmitter?.procedure;
		const bankrupt = procedure?.bankrupt;
		const arbitrCase = procedure?.arbitr_case;
		const arbitrManager = procedure?.arbitr_manager;
		const arbitrManagerPerson = arbitrManager?.person;
		const sro = arbitrManager?.sro;
		const requirementsReestr = $procedure?.requirements_reestr;
		const date = $dct?.date || moment().format('YYYY-MM-DD');
		const dateYear = $dct?.dateYear || moment().format('YYYY');
		const procedureDuration =
			$procedure?.hearing_date_and_time &&
			$procedure?.introduction_date &&
			Math.round(moment.duration(moment($procedure?.hearing_date_and_time).diff(moment($procedure?.introduction_date))).asMonths());

		const defineRegionId = () =>
			['bki', 'rsa', 'favt', 'fsis', 'bank', 'creditor', 'cryptoex'].includes($dct?.correspondent_kind || $substitutions?.correspondent_kind)
				? 'all'
				: $substitutions?.region_id || arbitrCase?.court_region_id || 'all';

		const requestedInformationDate = correspondentKind => {
			if (correspondentKind === 'fns') {
				return $procedure?.phase === 'bankruptcy_proceedings' ? $procedure?.bankruptcy_petition_date : $procedure?.resolution_introduction_date;
			} else {
				return $procedure?.bankruptcy_petition_date;
			}
		};
		const threeYearsFromDate = fromDate => (fromDate ? moment(fromDate).subtract(3, 'years').format('YYYY-MM-DD') : null);
		const debtCoverageByAssets = () => {
			const possessionsAmount =
				$transmitter.bankAccounts?.reduce((sum, bankAccount) => sum + (bankAccount.balance_rub ? parseFloat(bankAccount.balance_rub) : 0), 0) +
				$transmitter.receivables?.reduce((sum, receivable) => sum + (receivable.debt_amount ? parseFloat(receivable.debt_amount) : 0), 0) +
				$transmitter.possessions?.reduce((sum, possession) => sum + (possession.cost ? parseFloat(possession.cost) : 0), 0);

			const requirements = $transmitter.creditors_requirements?.filter(requirement => !requirement.excluded_date || requirement.excluded_date > date);

			const debtsAmount =
				requirements?.reduce((sum, creditorsRequirement) => sum + (creditorsRequirement.amount ? parseFloat(creditorsRequirement.amount) : 0), 0) +
				$transmitter.undeclaredDebts?.reduce((sum, undeclaredDebt) => sum + (undeclaredDebt.amount ? parseFloat(undeclaredDebt.amount) : 0), 0);

			let debtCoverage = debtsAmount !== 0 ? Math.round((possessionsAmount / debtsAmount) * 100) : possessionsAmount ? 100 : 0;

			if (debtCoverage > 100) {
				debtCoverage = 100;
			}

			return debtCoverage;
		};

		const meetings = ($transmitter.meetings && $transmitter.meetings.creditors) || [];

		const creditors_first_meeting = meetings
			.filter(e => e.first_meeting)
			.filter((e, _i, arr) => (arr.length == 1 ? true : ['held', 'not_held'].includes(e.status)))
			.reduce(
				(prev, curr, _i, arr) =>
					arr.length == 1 || isBlank(prev)
						? curr
						: curr.status == prev.status
							? new Date(curr.date_at) >= new Date(prev.date_at)
								? curr
								: prev
							: curr.status == 'held'
								? curr
								: prev,
				{},
			);

		const creditors_first_meeting_held = creditors_first_meeting?.status == 'held' ? creditors_first_meeting : {};
		const creditors_first_meeting_data = {
			date_at: creditors_first_meeting?.date_at,
			participants_qty: creditors_first_meeting?.participants_qty,
			participants_voting_qty: creditors_first_meeting?.participants_voting_qty,
			voting_sum: creditors_first_meeting?.voting_sum,
			agenda: creditors_first_meeting?.agenda,
			solutions: creditors_first_meeting?.solutions,
			arbitr_proposals: creditors_first_meeting?.arbitr_proposals,
		};

		const securedByPledgeKinds = ['Основной долг', 'Проценты по договору', 'Проценты за пользование займом', 'Судебные расходы', 'Государственная пошлина'];

		const notSecuredByPledgeKinds = [...securedByPledgeKinds, 'Обязательные платежи в бюджет'];

		const amountVotingDemands =
			$transmitter.creditors_requirements &&
			$transmitter.creditors_requirements
				.filter(
					requirement =>
						!requirement.off_reestr &&
						((+requirement.order_of_repayment === 2 && requirement.is_voting) ||
							(+requirement.order_of_repayment === 3 &&
								((requirement.secured_by_pledge && securedByPledgeKinds.includes(requirement.kind)) ||
									(!requirement.secured_by_pledge && notSecuredByPledgeKinds.includes(requirement.kind))))),
				)
				.reduce((sum, r) => sum + +r.amount, 0);

		const currentInsurancePolicy = $transmitter?.insurancePolicies?.find(p => (p.date_start || p.date) <= date && p.date_finish >= date);

		return {
			//kind: $dct?.kind,
			creditorsFirstMeetingExist: isPresent(creditors_first_meeting),
			creditorsFirstMeetingHeld: isPresent(creditors_first_meeting_held),
			analisysConclusion: $substitutions?.analisys_conclusion,
			amountStatedDemands: $substitutions?.amount_stated_demands,
			amountVotingDemands: $substitutions?.amount_voting_demands || amountVotingDemands,
			specialistsTotalPayment: $substitutions?.specialists_total_payment,
			// дата документа
			date,
			// название документа
			name: $substitutions?.name || null,
			// включить в отчет
			includeInReport: typeof $substitutions?.include_in_report === 'boolean' ? $substitutions?.include_in_report : false,
			// номер документа
			number: $dct?.number, // === 'б/н' ? null : $dct?.number,
			// Заявителем является
			procedureDeclarer: $procedure?.declarer?.name,
			// дата рождения должника
			bankruptBirthDate: $substitutions?.bankrupt_birth_date || bankrupt?.birth_date,
			// место рождения должника
			bankruptBirthPlace: $substitutions?.bankrupt_birth_place || bankrupt?.birth_place,
			// электронная почта должника
			bankruptEmail: $substitutions?.bankrupt_email || bankrupt?.email,
			// телефон должника
			bankruptPhone: $substitutions?.bankrupt_phone || bankrupt?.phone,
			// ИНН должника для ФЛ и ЮЛ
			bankruptInn: $substitutions?.bankrupt_inn || bankrupt?.inn,
			// КПП должника для ЮЛ
			bankruptKpp: $substitutions?.bankrupt_kpp || bankrupt?.kpp,
			// ОГРН должника для ЮЛ
			bankruptOgrn: $substitutions?.bankrupt_ogrn || bankrupt?.ogrn,
			// ОГРН должника для ЮЛ
			bankruptOgrnip: $substitutions?.bankrupt_ogrnip || bankrupt?.ogrnip,
			bankruptIsIndividualEntrepreneur: Object.keys($substitutions || {}).includes('bankrupt_is_individual_entrepreneur')
				? $substitutions?.bankrupt_is_individual_entrepreneur
				: isPresent(bankrupt?.ogrnip),
			// ОКВЭД должника для ЮЛ
			bankruptOkved: $substitutions?.bankrupt_okved || bankrupt?.okved,
			// СНИЛС должника для ФЛ
			bankruptSnils: $substitutions?.bankrupt_snils || bankrupt?.snils,
			// фамилия должника для ФЛ
			bankruptSurname: $substitutions?.bankrupt_surname || bankrupt?.surname,
			// имя должника для ФЛ
			bankruptName: $substitutions?.bankrupt_name || bankrupt?.name,
			// отчество должника для ФЛ
			bankruptPatronymic: $substitutions?.bankrupt_patronymic || bankrupt?.patronymic,
			// предыдущая фамилия должника для ФЛ
			bankruptSurnamePrevious: $substitutions?.bankrupt_surname_previous || bankrupt?.surname_previous,
			// предыдущее имя должника для ФЛ
			bankruptNamePrevious: $substitutions?.bankrupt_name_previous || bankrupt?.name_previous,
			// предыдущее отчество должника для ФЛ
			bankruptPatronymicPrevious: $substitutions?.bankrupt_patronymic_previous || bankrupt?.patronymic_previous,
			// предыдущие имена должника
			bankruptFullNamePrevious:
				$newObject && !$changedFields.includes('bankruptFullNamePrevious') ? bankrupt?.full_name_previous : $substitutions?.bankrupt_full_name_previous,
			// сокращённое наименование должника для ЮЛ
			bankruptShortName: $substitutions?.bankrupt_short_name || bankrupt?.short_name,
			// полное наименование должника для ЮЛ
			bankruptFullName: $substitutions?.bankrupt_full_name || bankrupt?.full_name,
			// адрес фактического проживания должника для ФЛ
			bankruptFactualAddress: $substitutions?.bankrupt_factual_address || bankrupt?.factual_address,
			// адрес регистрации должника для ФЛ
			bankruptRegAddress: $substitutions?.bankrupt_reg_address || bankrupt?.reg_address,
			// юридический адрес должника для ЮЛ
			bankruptLegalAddress: $substitutions?.bankrupt_legal_address || bankrupt?.legal_address,
			// почтовый адрес должника для ФЛ и ЮЛ
			bankruptMailingAddress: $substitutions?.bankrupt_mailing_address || bankrupt?.mailing_address,
			// дата выдачи паспорта должника
			bankruptPassportDate: $substitutions?.bankrupt_passport_date || bankrupt?.passport_date,
			// кем выдан паспорт должника
			bankruptPassportDepartment: $substitutions?.bankrupt_passport_department || bankrupt?.passport_department,
			// кем выдан паспорт должника
			bankruptPassportDepartmentCode: $substitutions?.bankrupt_passport_department_code || bankrupt?.passport_department_code,
			// серия и номер паспорта должника
			bankruptPassportNumber: $substitutions?.bankrupt_passport_number || bankrupt?.passport_number,
			// категория дожника
			bankruptCategoryId: $substitutions?.bankrupt_category_id || bankrupt?.category_id,
			// Является правопреемником
			bankruptWasReorg: $substitutions?.bankrupt_was_reorg || !!bankrupt?.reorg_id,
			// Сокращенное наименование реорганизованного ЮЛ
			bankruptShortNameReorg: $substitutions?.bankrupt_short_name_reorg || bankrupt?.reorg?.short_name,
			// Полное наименование реорганизованного ЮЛ
			bankruptFullNameReorg: $substitutions?.bankrupt_full_name_reorg || bankrupt?.reorg?.full_name,
			// ОГРН реорганизованного ЮЛ
			bankruptOgrnReorg: $substitutions?.bankrupt_ogrn_reorg || bankrupt?.reorg?.ogrn,
			// наименование суда
			courtId: $substitutions?.court_id || arbitrCase?.court_id,
			courtFullName: $substitutions?.court_full_name || arbitrCase?.court_full_name,
			// адрес суда
			courtMailingAddress: $substitutions?.court_mailing_address || arbitrCase?.court_mailing_address,
			// зал заседания по результатам процедуры
			courtroom: $substitutions?.courtroom || $procedure?.courtroom,
			// ФИО судьи
			refereeFullName: $substitutions?.referee_full_name || $procedure?.referee_full_name,
			// номер дела
			caseNumber: $substitutions?.case_number || arbitrCase?.number,
			// произвольного адресата
			arbitraryCorrespondent: $substitutions?.arbitrary_correspondent || null,
			// почтовый адрес произвольного адресата
			arbitraryMailingAddress: $substitutions?.arbitrary_mailing_address || null,
			// полное ФИО АУ
			arbitrManagerFullName: $substitutions?.arbitr_manager_full_name || arbitrManagerPerson?.full_name,
			// краткое ФИО АУ
			arbitrManagerShortName: $substitutions?.arbitr_manager_short_name || arbitrManagerPerson?.short_name,
			// фамилия АУ
			arbitrManagerSurname: $substitutions?.arbitr_manager_surname || arbitrManagerPerson?.surname,
			// дата рождения должника
			arbitrManagerBirthDate: $substitutions?.arbitr_manager_birth_date || arbitrManagerPerson?.birth_date,
			// место рождения должника
			arbitrManagerBirthPlace: $substitutions?.arbitr_manager_birth_place || arbitrManagerPerson?.birth_place,
			// имя АУ
			arbitrManagerName: $substitutions?.arbitr_manager_name || arbitrManagerPerson?.name,
			// отчество АУ
			arbitrManagerPatronymic: $substitutions?.arbitr_manager_patronymic || arbitrManagerPerson?.patronymic,
			// ИНН АУ
			arbitrManagerInn: $substitutions?.arbitr_manager_inn || arbitrManagerPerson?.inn,
			// СНИЛС АУ
			arbitrManagerSnils: $substitutions?.arbitr_manager_snils || arbitrManagerPerson?.snils,
			// страховая компания
			arbitrManagerInsuranceOrg: $substitutions?.arbitr_manager_insurance_org || currentInsurancePolicy?.organization,
			// номер страхового полиса
			arbitrManagerInsurancePolicyNumber: $substitutions?.arbitr_manager_insurance_policy_number || currentInsurancePolicy?.number,
			// дата страхового полиса
			arbitrManagerInsurancePolicyDate: $substitutions?.arbitr_manager_insurance_policy_date || currentInsurancePolicy?.date,
			// дата начала действия полиса
			arbitrManagerInsurancePolicyStartDate: $substitutions?.arbitr_manager_insurance_policy_start_date || currentInsurancePolicy?.date_start,
			// дата окончания действия полиса
			arbitrManagerInsurancePolicyFinishDate: $substitutions?.arbitr_manager_insurance_policy_finish_date || currentInsurancePolicy?.date_finish,
			// Длительность процедуры, мес.
			procedureDuration: $substitutions?.procedure_duration || procedureDuration,
			// страховая компания по доп. страховке АУ
			procedureInsuranceOrg: $substitutions?.procedure_insurance_org || $procedure?.insurance_org,
			// номер страхового полиса по доп. страховке АУ
			procedureInsurancePolicyNumber: $substitutions?.procedure_insurance_policy_number || $procedure?.insurance_policy_number,
			// дата страхового полиса по доп. страховке АУ
			procedureInsurancePolicyDate: $substitutions?.procedure_insurance_policy_date || $procedure?.insurance_policy_date,
			// дата начала действия полиса по доп. страховке АУ
			procedureInsurancePolicyStartDate:
				$substitutions?.procedure_insurance_policy_start_date || $substitutions?.procedure_insurance_policy_date || $procedure?.insurance_policy_start_date,
			// срок действия страхового полиса по доп. страховке АУ
			procedureInsurancePolicyFinishDate:
				$substitutions?.procedure_insurance_policy_finish_date ||
				($substitutions?.procedure_insurance_policy_date &&
					moment($substitutions?.procedure_insurance_policy_date).add(6, 'months').subtract(1, 'days').format('YYYY-MM-DD')) ||
				$procedure?.insurance_policy_finish_date,
			// почтовый адрес АУ
			arbitrManagerMailingAddress: $substitutions?.arbitr_manager_mailing_address || arbitrManagerPerson?.mailing_address,
			// адрес регистрации АУ
			arbitrManagerRegAddress: $substitutions?.arbitr_manager_reg_address || arbitrManagerPerson?.reg_address,
			// телефон АУ
			arbitrManagerPhone: $substitutions?.arbitr_manager_phone || arbitrManagerPerson?.phone,
			// электронная почта АУ
			arbitrManagerEmail: $substitutions?.arbitr_manager_email || arbitrManagerPerson?.email,
			// дата выдачи паспорта АУ
			arbitrManagerPassportDate: $substitutions?.arbitr_manager_passport_date || arbitrManagerPerson?.passport_date,
			// серия и номер паспорта АУ
			arbitrManagerPassportNumber: $substitutions?.arbitr_manager_passport_number || arbitrManagerPerson?.passport_number,
			// кем выдан паспорт АУ
			arbitrManagerPassportDepartment: $substitutions?.arbitr_manager_passport_department || arbitrManagerPerson?.passport_department,
			// кем выдан паспорт АУ
			arbitrManagerPassportDepartmentCode: $substitutions?.arbitr_manager_passport_department_code || arbitrManagerPerson?.passport_department_code,
			// Регистрационный номер АУ
			arbitrManagerRegNumber: $substitutions?.arbitr_manager_reg_number || arbitrManager?.reg_number,
			// данные отчетов об оценке
			assessmentReportData: $substitutions?.assessment_report_data || null,
			correspondentKind: $dct?.correspondent_kind || $substitutions?.correspondent_kind || null,
			correspondentId: $substitutions?.correspondent_id || null,
			// полное наименование корреспондента
			correspondentFullName: $substitutions?.correspondent_full_name || null,
			// вебсайт корреспондента
			correspondentWebsite: $substitutions?.correspondent_website || null,
			// email корреспондента
			correspondentEmail: $substitutions?.correspondent_email || null,
			// почтовый адрес корреспондента
			correspondentMailingAddress: $substitutions?.correspondent_mailing_address || null,
			// юридический адрес корреспондента
			correspondentLegalAddress: $substitutions?.correspondent_legal_address || null,
			// инн корреспондента
			correspondentInn: $substitutions?.correspondent_inn || null,
			// огрн корреспондента
			correspondentOgrn: $substitutions?.correspondent_ogrn || null,
			// должность корреспондента
			correspondentPosition: $substitutions?.correspondent_position || null,
			// место составления отчёта
			creationPlace: $substitutions?.creation_place || $procedure?.creation_place_docs,
			// дата введения процедуры (резолютивная часть)
			resolutionIntroductionDate: $substitutions?.resolution_introduction_date || $procedure?.resolution_introduction_date,
			// TODO: извращение для нейтрализации другого извращения. исправить оба.
			// дата введения процедуры (резолютивная часть)
			resolutionIntroductionDateShort: $substitutions?.resolution_introduction_date || $procedure?.resolution_introduction_date,
			// дата судебного акта о принятии завления
			bankruptcyPetitionDate: $substitutions?.bankruptcy_petition_date || $procedure?.bankruptcy_petition_date,
			// использовать первый стандартный абзац
			insertFirstParagraph: typeof $substitutions?.insert_first_paragraph === 'boolean' ? $substitutions?.insert_first_paragraph : true,
			// дата введения процедуры (полный текст)
			introductionDate: $substitutions?.introduction_date || $procedure?.introduction_date,
			// данные описей по инвентаризации
			inventoryDataByInventory: $substitutions?.inventory_data_by_inventory || null,
			// дата назначения АУ
			appointmentDate: $substitutions?.appointment_date || $procedure?.appointment_date,
			// дата назначения АУ (резолютивная часть)
			resolutionAppointmentDate: $substitutions?.resolution_appointment_date || $procedure?.resolution_appointment_date,
			// Место проведения анализа
			financialAnalysisPlace: $substitutions?.financial_analysis_place || $procedure?.creation_place_docs,
			// форма предоставления сведений
			formProvidingInformation: $substitutions?.form_providing_information, // || $options.formProvidingInformation && $options.formProvidingInformation[0].id,
			// дата судебного акта = дата введения процедуры (полный текст)
			judicialActDate: $substitutions?.judicial_act_date || procedure?.introduction_date,
			// копия судебного акта - количество экземпляров
			judicialActCopiesNumber: $substitutions?.judicial_act_copies_number || null,
			// копия судебного акта - количество листов
			judicialActPagesNumber: $substitutions?.judicial_act_pages_number || null,
			// дата заседания по результатам процедуры
			hearingDate: $substitutions?.hearing_date || $procedure?.hearing_date_and_time,
			// дата и время заседания по результатам процедуры
			hearingDateAndTime: $substitutions?.hearing_date_and_time || $procedure?.hearing_date_and_time,
			// виды объекта недвижимости
			kindRealty: $substitutions?.kind_realty || 'Все',
			// сторона акта
			transferringDocumentsParty: $substitutions?.transferring_documents_party,
			//
			transferringDocumentsAddress: $substitutions?.transferring_documents_address,
			//
			transferringDocumentsTime: $substitutions?.transferring_documents_time,
			// бывший АУ
			exArbitrManagerFullName: $substitutions?.ex_arbitr_manager_full_name,
			// способ получения данных
			obtainingMethod: $substitutions?.obtaining_method || null,
			// oбъект запроса
			requestObjects: $substitutions?.request_objects || 'bankrupt',
			spousesIds: $substitutions?.spouses_ids || null,
			// бухгалтерскую отчётность за публикацию оформить на
			recipientOfAccountingStatement: $substitutions?.recipient_of_accounting_statement || null,
			// СРО АУ
			sroId: $substitutions?.sro_id || sro?.id,
			// Полное название СРО АУ
			sroFullName: $substitutions?.sro_full_name || sro?.full_name,
			// Краткое название СРО АУ
			sroShortName: $substitutions?.sro_short_name || sro?.short_name,
			// ИНН СРО
			sroInn: $substitutions?.sro_inn || sro?.inn,
			// ОГРН СРО
			sroOgrn: $substitutions?.sro_ogrn || sro?.ogrn,
			// почтовый адрес СРО
			sroMailingAddress: $substitutions?.sro_mailing_address || sro?.mailing_address_full,
			sroMailingAddressFull: $substitutions?.sro_mailing_address_full || sro?.mailing_address_full,
			// юридический адрес СРО
			sroLegalAddress: $substitutions?.sro_legal_address || sro?.legal_address_full,
			sroLegalAddressFull: $substitutions?.sro_legal_address_full || sro?.legal_address_full,
			// регистрационный номер СРО
			sroRegNumber: $substitutions?.sro_reg_number || sro?.reg_number_sro, // ПРАВИТЬ!!!
			// Дата регистрации СРО
			sroRegDate: $substitutions?.sro_reg_date || sro?.reg_date, // ПРАВИТЬ!!!
			// Дата и время заседания по результатам процедуры
			completionDateTime: $substitutions?.completion_date_time || procedure?.hearing_date_and_time,
			// дата окончания процедуры
			completionDate: $substitutions?.completion_date || procedure?.completion_date,
			// дата окончания процедуры после продления
			completionDateAfterRenewal: $substitutions?.prolongations || $procedure?.prolongations,
			// период продления процедуры, мес.
			renewalPeriod: $substitutions?.prolongations || $procedure?.prolongations,
			prolongations: $substitutions?.prolongations || $procedure?.prolongations,
			// номер газеты «Коммерсантъ»
			publicationNewspaperNumber: $substitutions?.publication_newspaper_number || $procedure?.publication_newspaper_number,
			// Номер публикации в газете «Коммерсантъ»
			publicationNumberNewspaper: $substitutions?.publication_number_newspaper || $procedure?.publication_number_newspaper,
			// дата публикации в «Коммерсантъ»
			publicationNewspaperDate: $substitutions?.publication_newspaper_date || $procedure?.publication_newspaper_date,
			publicationNewspaperCopiesNumber: $substitutions?.publication_newspaper_copies_number || null,
			publicationNewspaperPagesNumber: $substitutions?.publication_newspaper_pages_number || null,
			// номер публикации «Федресурс»
			publicationFedresursNumber: $substitutions?.publication_fedresurs_number || $procedure?.publication_fedresurs_number,
			// дата публикации в «Федресурс»
			publicationFedresursDate: $substitutions?.publication_fedresurs_date || $procedure?.publication_fedresurs_date,
			publicationFedresursCopiesNumber: $substitutions?.publication_fedresurs_copies_number || null,
			publicationFedresursPagesNumber: $substitutions?.publication_fedresurs_pages_number || null,
			// дата уведомления кредиторов о введении процедуры
			creditorsNotificationDateAboutProcedureIntroduction: $substitutions?.creditors_notification_date_about_procedure_introduction || null,
			// количество требований кредиторов, рассмотренных судом
			creditorsClaimsNumberCourtConsidered: $substitutions?.creditors_claims_number_court_considered || null,
			// количество возражений АУ и должника, рассмотренных судом
			debtorAndArbitrManagerObjectionsNumberCourtConsidered: $substitutions?.debtor_and_arbitr_manager_objections_number_court_considered || null,
			// конец периода запрашиваемых сведений
			endDateOfRequestedInformationPeriod: $substitutions?.end_date_of_requested_information_period || $procedure?.resolution_introduction_date,
			numberJudicialActsOnInclusionInRequirementsReestr: $substitutions?.number_judicial_acts_on_inclusion_in_requirements_reestr || null,
			numberJudicialActsOnRefusalToIncludedInRequirementsReestr: $substitutions?.number_judicial_acts_on_refusal_to_included_in_requirements_reestr || null,
			// Количество кредиторов, включенных в РТК на дату 1-го собрания
			numberCreditorsIncludedInRequirementsReestrForFirstMeeting: $substitutions?.number_creditors_included_in_requirements_reestr_for_first_meeting || null,
			// количество уведомленных кредиторов
			numberNotifiedCreditors: $substitutions?.number_notified_creditors || null,
			// сумма требований, включенных в РТК на дату 1-го собрания (руб.)
			amountClaimsIncludedInRequirementsReestrForFirstMeeting: $substitutions?.amount_claims_included_in_requirements_reestr_for_first_meeting || null,
			// дата, на которую проведен финанализ
			financialAnalysisDate: $substitutions?.financial_analysis_date || $procedure?.introduction_date,
			// дата анализа документов по регистрации прав собственности
			documentsAnalysisDateToPropertyRightsRegistration: $substitutions?.documents_analysis_date_to_property_rights_registration || date,
			// текст документа || дополнительный текст
			arbitraryText: isPresent($substitutions?.arbitrary_text) ? $substitutions?.arbitrary_text : $substitutions?.dct_arbitrary_text,
			//
			procedurePhaseId: $substitutions?.procedure_phase_id || $procedure?.phase,
			// номер описи имущества
			propertyInventoryNumber: $substitutions?.property_inventory_number || null,
			// дата описи имущества
			propertyInventoryDate: $substitutions?.property_inventory_date || null,
			// дата оценки имущества
			propertyAppraisalDate: $substitutions?.property_appraisal_date || null,
			// дата, на которую запрашиваются сведения
			requestedInformationDate:
				$substitutions?.requested_information_date || requestedInformationDate($dct?.correspondent_kind || $substitutions?.correspondent_kind),
			// начало периода запрашиваемых сведений
			startDateOfRequestedInformationPeriod:
				$substitutions?.start_date_of_requested_information_period || threeYearsFromDate($procedure?.bankruptcy_petition_date),

			realtyLocation: $options.region?.find(option => parseInt(option.id) === parseInt($substitutions?.realty_location_id))?.text || '',
			// расположение объектов недвижимости
			realtyLocationId:
				($substitutions?.realty_location_id && $options.region?.find(option => parseInt(option.id) === parseInt($substitutions?.realty_location_id))) ||
				($options.region?.length && $options.region.find(region => region.full_name === 'Российская Федерация')),

			fictitiousOrDeliberateBankruptcySignsVerificationDate: $substitutions?.fictitious_or_deliberate_bankruptcy_signs_verification_date || date,
			// дата протокола 1-го собрания кредиторов
			creditorsFirstMeetingMinutesDate: creditors_first_meeting_data?.date_at, //$substitutions?.creditors_first_meeting_minutes_date || creditors_first_meeting_data?.date_at,
			// количество участников 1-го собрания кредиторов
			creditorsFirstMeetingParticipantsNumber: creditors_first_meeting_data.participants_qty, //$substitutions?.creditors_first_meeting_participants_number || creditors_first_meeting_data.participants_qty,
			// количество участников 1-го собрания кредиторов с правом голоса
			creditorsFirstMeetingVotingParticipantsNumber: creditors_first_meeting_data.participants_voting_qty, //$substitutions?.creditors_first_meeting_voting_participants_number || creditors_first_meeting_data.participants_voting_qty,
			// сумма требований участников 1-го собрания кредиторов (руб.)
			creditorsFirstMeetingParticipantsClaimsAmount: creditors_first_meeting_data.voting_sum, //$substitutions?.creditors_first_meeting_participants_claims_amount || creditors_first_meeting_data.voting_sum,
			// повестка дня собрания 1-го собрания кредиторов
			creditorsFirstMeetingAgenda: creditors_first_meeting_data.agenda, //$substitutions?.creditors_first_meeting_agenda || creditors_first_meeting_data.agenda,
			// решения собрания 1-го собрания кредиторов
			creditorsFirstMeetingSolutions: creditors_first_meeting_data.solutions, //$substitutions?.creditors_first_meeting_solutions || creditors_first_meeting_data.solutions,
			// предложение АУ по вопросу 1-го собрания кредиторов
			creditorsFirstMeetingArbitrProposals: creditors_first_meeting_data.arbitr_proposals, //$substitutions?.creditors_first_meeting_arbitr_proposals || creditors_first_meeting_data.arbitr_proposals,
			wasDebtRestructuringApproved: $substitutions?.was_debt_restructuring_approved || null,
			// результаты финанализа
			financialAnalysisResults: $substitutions?.financial_analysis_results || null,
			requirementsReestrClosingDate: $substitutions?.requirements_reestr_closing_date || requirementsReestr?.closing_date,

			numberLendersIncludedInRequirementsReestr:
				$substitutions?.number_lenders_included_in_requirements_reestr || $creditorsRequirements?.numberCounterparties || null,

			numberSheetsRequirementsReestr: $substitutions?.number_sheets_requirements_reestr || null,
			numberSheetsDocumentsForSettlementClaims: $substitutions?.number_sheets_documents_for_settlement_claims || null,
			numberSheetsDocumentsForSaleProperty: $substitutions?.number_sheets_documents_for_sale_property || null,
			region: $options.region?.find(option => parseInt(option.id) === parseInt($substitutions?.region_id))?.text || '',
			regionId: $substitutions?.region_id || defineRegionId(),
			bankruptRegionId: $substitutions?.bankrupt_region_id || arbitrCase?.court_region_id,
			causeOrgRegionId: $substitutions?.cause_org_region_id || defineRegionId(),

			registrarChoice: $substitutions?.registrar_choice || null,

			// реестродержатель
			registrar: $substitutions?.registrar || ($dct?.registrar_choice === 'arbitr_manager' ? 'АУ' : null),
			// адрес реестродержателя
			registrarMailingAddress: $substitutions?.registrar_mailing_address || null,
			// страховая компания по страховке реестродержателя
			registrarInsuranceOrg: $substitutions?.registrar_insurance_org || null,
			// номер и дата полиса по страховке реестродержателя
			registrarInsurancePolicy: $substitutions?.registrar_insurance_policy || null,
			// балансовая стоимость имущества (тыс. руб.)
			bookPropertyValue: $substitutions?.book_property_value || 0,
			// рыночная стоимость имущества (тыс. руб.)
			marketPropertyValue: $substitutions?.market_property_value || 0,
			// дата уведомления работников
			employeeNotificationDate: $substitutions?.employee_notification_date || null,
			// есть признаки фиктивного/преднамеренного банкротства
			hasFictitiousWillfulBankruptcySigns: $substitutions?.has_fictitious_willful_bankruptcy_signs || false,
			// признаки фиктивного/преднамеренного банкротства
			fictitiousWillfulBankruptcySigns: $substitutions?.fictitious_willful_bankruptcy_signs || null,
			// есть признаки фиктивного банкротства
			hasFictitiousBankruptcySigns: $substitutions?.has_fictitious_bankruptcy_signs || false,
			// признаки фиктивного банкротства
			fictitiousBankruptcySigns: $substitutions?.fictitious_bankruptcy_signs || null,
			// есть признаки преднамеренного банкротства
			hasWillfulBankruptcySigns: $substitutions?.has_willful_bankruptcy_signs || false,
			// признаки преднамеренного банкротства
			willfulBankruptcySigns: $substitutions?.willful_bankruptcy_signs || null,
			// задолженность перед кредиторами из заявления должника (руб.)
			debtToCreditorsFromStatementOfDebtor: $substitutions?.debt_to_creditors_from_statement_of_debtor || 0,
			// АУ поступал план реструктуризации
			arbitrManagerReceivedRestructuringPlan: $substitutions?.arbitr_manager_received_restructuring_plan || false,
			// выявлены факты незаконного/недобросовестного поведения должника
			hasFactsOfIllegalUnfairBehaviorOfDebtor: $substitutions?.has_facts_of_illegal_unfair_behavior_of_debtor || false,
			// факты незаконного/недобросовестного поведения должника
			factsOfIllegalUnfairBehaviorOfDebtor: $substitutions?.facts_of_illegal_unfair_behavior_of_debtor || null,
			// должник предоставил АУ документы
			receivableSubmittedDocumentsToArbitrManager: $substitutions?.receivable_submitted_documents_to_arbitr_manager || false,
			// должник привлекался к ответственности за неправомерные действия при банкротстве, преднамеренное/фиктивное банкротство
			receivableWasHeldLiableForIllegalActionsInBankruptcy: $substitutions?.receivable_was_held_liable_for_illegal_actions_in_bankruptcy || false,
			// у должника есть дополнительные расходы
			bankruptHasAdditionalExpenses: $substitutions?.bankrupt_has_additional_expenses || false,
			// ежемесячные прочие расходы должника (руб./мес.)
			monthlyExpensesOfDebtorOther: $substitutions?.monthly_expenses_of_debtor_other || 0,
			// ежемесячные расходы должника на алименты (руб./мес.)
			monthlyExpensesOfDebtorAlimony: $substitutions?.monthly_expenses_of_debtor_alimony || 0,
			// ежемесячные расходы должника на лечение (руб./мес.)
			monthlyExpensesOfDebtorTherapy: $substitutions?.monthly_expenses_of_debtor_therapy || 0,
			// должник в браке
			bankruptMarried: $substitutions?.bankrupt_married || false,
			// переводить зарплату на основной счёт
			transferSalaryToMainAccount: $substitutions?.transfer_salary_to_main_account || false,
			// Количество несовершеннолетних детей должника для анализа платежеспособности должника
			numberOfMinorChildrenOfBankruptForAnalysis:
				$substitutions?.number_of_minor_children_of_bankrupt_for_analysis ||
				($transmitter.children || []).filter(ch => (isPresent(ch.birth_date) ? moment().diff(moment(ch.birth_date), 'years') <= 18 : true)).length,
			// за период были сделки с активами должника
			hasTransactionsWithAssetsOfBankrupt: $substitutions?.has_transactions_with_assets_of_bankrupt || null,
			// данные о сделках с активами должника
			transactionsWithAssetsOfBankrupt: $substitutions?.transactions_with_assets_of_bankrupt || null,
			// прожиточный минимум для должника (руб.)
			livingWageForBankrupt: $substitutions?.living_wage_for_bankrupt || $procedure?.additions?.living_wage_for_bankrupt,
			// прожиточный минимум для детей (руб.)
			livingWageForChildren: $substitutions?.living_wage_for_children || $procedure?.additions?.living_wage_for_children,
			// источник данных о прожиточном минимуме
			livingWageSourceData: $substitutions?.living_wage_source_data || null,
			// год данных о прожиточном минимуме
			livingWageYear: $substitutions?.living_wage_year || dateYear,
			// предвидится доп. источник дохода должника
			incomeAdditionalSourceOfBankrupt: $substitutions?.income_additional_source_of_bankrupt || null,
			// должник имеет постоянный источник дохода
			bankruptHasConstantSourceOfIncome: $substitutions?.bankrupt_has_constant_source_of_income || $procedure?.additions?.bankrupt_has_constant_source_of_income,
			// внешний фактор: экономическое состояние в регионе, %
			externalFactorEconomicSituationInRegion: $substitutions?.external_factor_economic_situation_in_region || 50,
			// внешний фактор: стоимость продуктовой корзины, %
			externalFactorCostOfGroceryBasket: $substitutions?.external_factor_cost_of_grocery_basket || 0,
			// Внешний фактор: средний доход населения, %
			externalFactorAverageIncomeOfPopulation: $substitutions?.external_factor_average_income_of_population || 20,
			// Внешний фактор: уровень роста цен, %
			externalFactorPriceGrowthRate: $substitutions?.external_factor_price_growth_rate || 30,
			// Внешний фактор: количество безработных, %
			externalFactorNumberOfUnemployed: $substitutions?.external_factor_number_of_unemployed || 0,
			// Внешний фактор: прибыль компаний, %
			externalFactorCompanyProfits: $substitutions?.external_factor_company_profits || 0,
			// Внутренний фактор: размер долгов, %
			internalFactorIndebtedness: $substitutions?.internal_factor_indebtedness || 50,
			// Внутренний фактор: уровень доходов, %
			internalFactorIncomeLevel: $substitutions?.internal_factor_income_level || 25,
			// Внутренний фактор: количество кредитов, %
			internalFactorNumberOfLoans: $substitutions?.internal_factor_number_of_loans || 25,
			// Есть судебный акт о признании единственного жилья должника
			hasJudicialActOnRecognitionOnlyHomeBankrupt: $substitutions?.has_judicial_act_on_recognition_only_home_bankrupt || null,
			// Банки предоставили выписки по счетам
			banksProvidedAccountStatements: $substitutions?.banks_provided_account_statements || null,
			// Выводы по результатам анализа выписок банков
			conclusionsBasedOnAnalysisOfBankStatements: $substitutions?.conclusions_based_on_analysis_of_bank_statements || null,
			// Покрытие активами долгов должника, %
			debtCoverageByAssets: debtCoverageByAssets(), // $substitutions?.debt_coverage_by_assets || debtCoverageByAssets(),
			// Тот же, что и адрес регистрации
			bankruptRegAndFactualAddressesMatch: $substitutions?.bankrupt_reg_and_factual_addresses_match || null,
			// Дата, с которой анализируются сделки
			dateFromWhichAnalyzed:
				$substitutions?.date_from_which_analyzed ||
				threeYearsFromDate($substitutions?.bankruptcy_petition_date) ||
				threeYearsFromDate($procedure?.bankruptcy_petition_date),
			// Выявлены сделки, подлежащие оспариванию
			identifiedTransactionsSubjectToDispute: $substitutions?.identified_transactions_subject_to_dispute || null,
			// Информация о сделках, подлежащих оспариванию
			informationOnTransactionsSubjectToDispute: $substitutions?.information_on_transactions_subject_to_dispute || null,
			// Основание оспаривания сделок
			groundsForDisputingTransactions: $substitutions?.grounds_for_disputing_transactions || null,
			// Исследуемый период фиктивности/преднамеренности банкротства: с
			fictitiousBankruptcyInvestigatedPeriod: [
				($substitutions?.fictitious_bankruptcy_investigated_period && $substitutions?.fictitious_bankruptcy_investigated_period[0]) || threeYearsFromDate(date),
				($substitutions?.fictitious_bankruptcy_investigated_period && $substitutions?.fictitious_bankruptcy_investigated_period[1]) || date,
			],
			signature: $substitutions?.signature || false,
			// Meeting's data
			meetingForm: $substitutions?.meeting_form,
			meetingDateAt: $substitutions?.meeting_date_at,
			meetingTimeAt: $substitutions?.meeting_time_at,
			meetingNote: $substitutions?.meeting_note,
			meetingFromAt: $substitutions?.meeting_from_at,
			meetingToAt: $substitutions?.meeting_to_at,
			meetingAddress: $substitutions?.meeting_address,
			meetingProtocolAddress: $substitutions?.meeting_protocol_address || $procedure?.creation_place_docs,
			meetingReadAt: $substitutions?.meeting_read_at,
			meetingReadFromAt: $substitutions?.meeting_read_from_at,
			meetingReadToAt: $substitutions?.meeting_read_to_at,
			meetingReadAddress: $substitutions?.meeting_read_address,
			meetingQuestions: $substitutions?.meeting_questions,

			meetingQuestionId: $substitutions?.meeting_question_id,
			meetingQuestionBulletinForm: $substitutions?.meeting_bulletin_form,
			meetingQuestionQuestion: $substitutions?.meeting_question_question,
			meetingQuestionSolution: $substitutions?.meeting_question_solution,
			meetingQuestionSecuredVotes: $substitutions?.meeting_question_secured_votes,
			meetingParticipantId: $substitutions?.meeting_participant_id,
			meetingParticipantVotingSum: $substitutions?.meeting_participant_voting_sum,
			meetingParticipantTotalVotingSum: $substitutions?.meeting_participant_total_voting_sum,
			meetingParticipantVotesPercent: $substitutions?.meeting_participant_votes_percent,
			meetingParticipantRepresentativeName: $substitutions?.meeting_participant_representative_name,
			// meetingParticipantBirthday:    $substitutions?.meeting_participant_birthday,
			// meetingParticipantBirthplace:  $substitutions?.meeting_participant_birthplace,
			// meetingParticipantSnils:       $substitutions?.meeting_participant_snils,
			// meetingParticipantInn:         $substitutions?.meeting_participant_inn,
			// meetingParticipantRegAddress:  $substitutions?.meeting_participant_reg_address,
			meetingParticipantKind: $substitutions?.meeting_participant_kind,
			meetingParticipantIsPerson: $substitutions?.meeting_participant_is_person,
			meetingVotingQty: $substitutions?.meeting_voting_qty,
			meetingVotingSum: $substitutions?.meeting_voting_sum,
			meetingFirst: $substitutions?.meeting_first,
			meetingReason: $substitutions?.meeting_reason,
			meetingChairman: $substitutions?.meeting_chairman,
			meetingSecretary: $substitutions?.meeting_secretary,
			meetingVotingStatus: $substitutions?.meeting_voting_status,
			meetingParticipantVotingProc: $substitutions?.meeting_participant_voting_proc,
			compositeText: $substitutions?.composite_text,
			outgoingRequestId: $substitutions?.outgoing_request_id,
			outgoingRequestCorrespondentKind: $substitutions?.outgoing_request_correspondent_kind,
			outgoingRequestCorrespondentFullName: $substitutions?.outgoing_request_correspondent_full_name,
			outgoingRequestCorrespondentMailingAddress: $substitutions?.outgoing_request_correspondent_mailing_address,
			outgoingRequestNumber: $substitutions?.outgoing_request_number,
			outgoingRequestDate: $substitutions?.outgoing_request_date,
			bankruptLeaderFullName: $substitutions?.bankrupt_leader_full_name || $procedure?.bankrupt?.leader_full_name,
			bankruptLeaderRequestReceived: $substitutions?.bankrupt_leader_request_received,
			bankruptLeaderRequestReceivedDate: $substitutions?.bankrupt_leader_request_received_date,

			meetingSolutions: $substitutions?.meeting_solutions,
			votingMeetingStatus: $substitutions?.meeting_voting_status,

			hasBeenDepositedArbitrManagerRemunerationIntoCourtDeposit: $substitutions?.has_been_deposited_arbitr_manager_remuneration_into_court_deposit,
			hasBeenExpensesFundsDepositedIntoCourtDeposit: $substitutions?.has_been_expenses_funds_deposited_into_court_deposit,
			fundsOnCourtDepositCost: $substitutions?.funds_on_court_deposit_cost || 0,
			estimatedCourtCost: $substitutions?.estimated_court_cost || 0,
		};
	},
);
