/** @format */

import { readable, derived, writable } from 'svelte/store';
import { procedure } from 'base_stores';

export const types = readable([
	{
		id: 'Person',
		text: 'Физическое лицо',
		incompatibleRoles: {
			Person: ['bank', 'stockbroker', 'cryptoex', 'debtor_participant'],
			Organization: ['bank', 'stockbroker', 'cryptoex', 'worker'],
		},
	},
	{
		id: 'Organization',
		text: 'Юридическое лицо',
		incompatibleRoles: {
			Person: ['worker', 'kdl', 'debtor_participant'],
			Organization: ['worker', 'kdl'],
		},
	},
	{
		id: 'Individual_Entrepreneur',
		text: 'ИП',
		incompatibleRoles: {
			Person: ['bank', 'stockbroker', 'cryptoex', 'worker', 'kdl', 'debtor_participant'],
			Organization: ['bank', 'stockbroker', 'cryptoex', 'worker', 'kdl'],
		},
	},
]);

export const roles = readable([
	{ id: 'declarer', text: 'Заявитель', incompatibleRoles: [], disabledRoles: ['cryptoex'] },
	{ id: 'creditor', text: 'Кредитор', incompatibleRoles: [], disabledRoles: [] },
	{ id: 'current_creditor', text: 'Текущий кредитор', incompatibleRoles: [], disabledRoles: [] },
	{ id: 'bank', text: 'Банк', incompatibleRoles: [], disabledRoles: ['stockbroker', 'cryptoex'] },
	{ id: 'stockbroker', text: 'Брокер', incompatibleRoles: [], disabledRoles: ['bank', 'cryptoex'] },
	{ id: 'payee', text: 'Получатель платежа', incompatibleRoles: [], disabledRoles: [] },
	{ id: 'debtor', text: 'Дебитор', incompatibleRoles: [], disabledRoles: [] },
	{ id: 'debtor_participant', text: 'Участник должника (на дату введения процедуры)', incompatibleRoles: [], disabledRoles: [] },
	{ id: 'meeting_participant', text: 'Участник собрания', incompatibleRoles: [], disabledRoles: [] },
	{ id: 'worker', text: 'Работник', incompatibleRoles: ['bank', 'stockbroker', 'cryptoex'], disabledRoles: [] },
	{ id: 'kdl', text: 'Контролирующее должника лицо', incompatibleRoles: [], disabledRoles: [] },
	{ id: 'cryptoex', text: 'Криптобиржа', incompatibleRoles: [], disabledRoles: ['declarer', 'bank', 'stockbroker'] },
]);

export const rolesRu = derived(roles, $roles => {
	const translations = {};
	for (let role of $roles) {
		translations[role.id] = role.text;
	}
	return translations;
});

export const counterparty = (() => {
	const { subscribe, set } = writable({
		id: null,
		contractor_type: null,
		contractor_id: null,
		roles: [],
		bank_bik: null,
		bank_name: null,
		bank_cor_account: null,
		bank_account: null,
		bank_rkc: null,
		can_be_destroyed: true,
		additions: {},
		errors: {},
	});

	const clear = () =>
		set({
			id: null,
			contractor_type: null,
			contractor_id: null,
			roles: [],
			bank_bik: null,
			bank_name: null,
			bank_cor_account: null,
			bank_account: null,
			bank_rkc: null,
			can_be_destroyed: true,
			additions: {},
			errors: {},
		});

	return { subscribe, set, clear };
})();

export const actualRoles = derived([types, roles, counterparty, procedure], ([$types, $roles, $counterparty, $procedure]) => {
	const counterpartyIncompatibleRoles = [...new Set(($counterparty?.roles?.map(cr => $roles.find(r => r.id == cr)?.incompatibleRoles) || []).flat())]

	const counterpartyDisabledRoles = [...new Set(($counterparty?.roles?.map(cr => $roles.find(r => r.id == cr)?.disabledRoles) || []).flat())]

	const byBankruptTypeIncompatibleRoles = $types.find(t => t.id == $counterparty.contractor_type)?.incompatibleRoles[$procedure?.bankrupt_type] || []

	let selectedRoles = $roles.filter(role => ![...new Set([...counterpartyIncompatibleRoles, ...byBankruptTypeIncompatibleRoles])]?.includes(role.id))

	return selectedRoles.map(role => {
		if (counterpartyDisabledRoles.includes(role.id) || $counterparty.id && role.id == 'cryptoex' && $counterparty?.roles?.includes(role.id)) {
			return { ...role, fixed: true }
		} else {
			return role
		}
	});
});
