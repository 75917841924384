<!-- @format -->
<script>
	import { createEventDispatcher, tick } from 'svelte';
	import { fetchPost } from 'utils/fetch_helpers';
	import { isPresent, isBlank, toArray, formatSum, formatDate } from 'utils/tools';
	import ErrorMessage from '~/svelte/components/message.svelte';
	import Form from '~/svelte/components/ui/form/_form.svelte';
	import Field from '~/svelte/components/ui/form/_form_field.svelte';
	import Select from '~/svelte/components/ui/svelte-select/select.svelte';
	import InputDate from '~/svelte/components/ui/input_date.svelte';
	import InputMoney from '~/svelte/components/ui/input_money.svelte';
	import RepaymentOrderPersonInfo from '~/svelte/_shared/repayment_order_person_info_block.svelte';
	import RepaymentOrderOrganizationInfo from '~/svelte/_shared/repayment_order_organization_info_block.svelte';
	import IconInfo from '~/svelte/_shared/icon-info.svelte';
	import { procedure } from 'base_stores';

	const dispatch = createEventDispatcher();

	export let formOptions;
	export let isCompleted;
	export const submitForm = () => formControl.submitForm();
	export const deleteItem = () => formControl.deleteItem();
	export const getItem = () => formControl.getItem();
	export const setItem = changes => formControl.setItem(changes);
	export const getData = () => data;
	export const setData = changes => (data = { ...data, ...changes });
	export let disabled = false;

	let formControl;
	let form, errorsBase, handleChange;
	let data = {};

	const setInitialazedForm = instance => {
		form = instance.form;
		errorsBase = instance.errorsBase;
		handleChange = instance.handleChange;
	};

	const setDictionariesValues = values => {
		data = { ...values };
		data.counterparties = [{ value: -1, label: 'Добавить кредитора', action: true }, ...data.counterparties];
	};
	const setSubmitParams = () => ({});

	const handleNewCounterparty = () => dispatch('newCounterparty');
	const handleEditPayout = id => dispatch('editPayout', id);

	let repaymentOrderInfoShow = false;
	let auto_filled_out_counterparty = false;

	const setDefaultCounterparty = () => {
		if (isPresent(data.default_counterparty_id) && $form.source_cost == 'manager' && isBlank($form.counterparty_id)) {
			$form.counterparty_id = data.default_counterparty_id;
			tick().then(() => (auto_filled_out_counterparty = true));
		}
	};

	const createExpenseGoalItem = async ({ detail: newItem }) => {
		if (isPresent(newItem.value) && newItem.value == newItem.label) {
			const result = await fetchPost('/api/private/expense_goals', { expense_goal: { name: newItem.label } });
			const item = isPresent(result.name) ? { value: -1, label: result.name[0] } : result.item;

			data.expense_goals = [item, ...data.expense_goals];
			$form.expense_goal = item.value;
		}
	};

	$: console.log('data.source_costs: ' + JSON.stringify(data?.counterparties));
	$: console.log('$form.counterparty_id: ' + $form?.counterparty_id);

	$: isPresent($form) && setDefaultCounterparty($form.source_cost);
	$: if (isPresent($form) && isPresent($form.counterparty_id)) auto_filled_out_counterparty = false;
</script>

<Form
	bind:this={formControl}
	bind:isCompleted
	formOptions={{ ...formOptions, model: 'running_cost', controller: 'running_costs' }}
	{setDictionariesValues}
	{setSubmitParams}
	{setInitialazedForm}
>
	<!-- Источник расхода -->
	<Field name="source_cost" label="Источник расхода" required={true}>
		<Select
			isDisabled={disabled}
			name="source_cost"
			items={data.source_costs}
			value={$form.source_cost}
			onchange={handleChange}
			containerClasses="form-control"
			placeholder="Выбрать источник"
		></Select>
	</Field>
	<!-- Текущий кредитор -->
	<Field name="counterparty_id" label="Текущий кредитор" required={true}>
		<Select
			isDisabled={disabled}
			name="counterparty_id"
			items={data.counterparties}
			value={$form.counterparty_id}
			onchange={handleChange}
			on:action={handleNewCounterparty}
			containerClasses="form-control"
			placeholder="Выбрать кредитора"
		></Select>

		{#if auto_filled_out_counterparty}
			<span class="form-text text-muted m-b-none m-l-xs">AI заполнил на основании Источник расхода</span>
		{/if}
	</Field>
	<!-- Дата документа -->
	<Field name="date_at" label="Дата документа" required={true} short={true}>
		<InputDate {disabled} name="date_at" bind:value={$form.date_at} klass="form-control required-border" />
	</Field>
	<!-- Номер документа -->
	<Field name="num" label="Номер документа" required={true}>
		<input {disabled} name="num" type="text" class="form-control" bind:value={$form.num} on:change={handleChange} placeholder="15" />
	</Field>
	<!-- Очередность погашения -->
	<Field name="repayment_order" label="Очередность погашения" required={true}>
		<Select
			isDisabled={disabled}
			name="repayment_order"
			items={data.repayment_orders}
			value={$form.repayment_order}
			onchange={handleChange}
			containerClasses="form-control"
			placeholder="Выбрать очередь"
		></Select>

		<div
			class="wrapper-info-icon"
			role="button"
			tabindex="0"
			on:mouseover={() => (repaymentOrderInfoShow = true)}
			on:mouseout={() => (repaymentOrderInfoShow = false)}
			on:focus={() => (repaymentOrderInfoShow = true)}
			on:blur={() => (repaymentOrderInfoShow = false)}
		>
			<IconInfo />
		</div>

		{#if repaymentOrderInfoShow}
			<div class="repayment_order__popover show fade">
				{#if $procedure.bankrupt_type == 'Organization'}
					<RepaymentOrderOrganizationInfo />
				{:else if $procedure.bankrupt_type == 'Person'}
					<RepaymentOrderPersonInfo />
				{/if}
			</div>
		{/if}
	</Field>
	<!-- Вид расхода -->
	<Field name="expense_type" label="Вид расхода" required={true}>
		<Select
			isDisabled={disabled}
			name="expense_type"
			items={data.expense_types}
			value={$form.expense_type}
			onchange={handleChange}
			containerClasses="form-control"
			placeholder="Выбрать вид"
		></Select>
	</Field>
	<!-- Цель расхода -->
	<Field name="expense_goal" label="Цель расхода">
		<Select
			isDisabled={disabled}
			name="expense_goal_id"
			items={data.expense_goals}
			value={$form.expense_goal_id}
			onchange={handleChange}
			isCreatable={true}
			on:select={createExpenseGoalItem}
			placeholder="Выбрать цель"
		></Select>
	</Field>
	<!-- Процедура, в ходе которой возникло обязательство -->
	<Field name="procedure_kind" label="Процедура, в ходе которой возникло обязательство" required={true}>
		<Select
			isDisabled={disabled}
			name="procedure_kind"
			items={data.procedure_kinds}
			value={$form.procedure_kind}
			onchange={handleChange}
			containerClasses="form-control"
			placeholder="Выбрать процедуру"
		></Select>
	</Field>
	<!-- Основание возникновения расхода -->
	<Field name="expense_reason" label="Основание возникновения расхода">
		<input
			{disabled}
			name="expense_reason"
			type="text"
			class="form-control"
			bind:value={$form.expense_reason}
			on:change={handleChange}
			placeholder="Уведомления кредиторов"
		/>
	</Field>
	<!-- Дата и № протокола собрания (комитета) кредиторов при наличии согласования -->
	<Field name="protocol" label="Дата и № протокола собрания (комитета) кредиторов при наличии согласования">
		<input
			{disabled}
			name="protocol"
			type="text"
			class="form-control"
			bind:value={$form.protocol}
			on:change={handleChange}
			placeholder="Протокол №0 от 00.00.0000 г."
		/>
	</Field>
	<!-- Получатель платежа -->
	<Field name="payment_receiver" label="Получатель платежа">
		<input
			{disabled}
			name="payment_receiver"
			type="text"
			class="form-control"
			bind:value={$form.payment_receiver}
			on:change={handleChange}
			placeholder="АО “Почта России”"
		/>
	</Field>
	<!-- Расход связан с продажей залогового имущества/дебиторки -->
	<Field name="possession_id" label="Расход связан с продажей залогового имущества">
		<Select
			isDisabled={disabled}
			name="possession_id"
			items={data.possessions}
			value={$form.possession_id}
			onchange={handleChange}
			containerClasses="form-control"
			placeholder="Выбрать залоговое имущество"
		></Select>
	</Field>
	<Field name="receivable_id" label="Расход связан с продажей залоговой дебиторки">
		<Select
			isDisabled={disabled}
			name="receivable_id"
			items={data.receivables}
			value={$form.receivable_id}
			onchange={handleChange}
			containerClasses="form-control"
			placeholder="Выбрать залоговую дебиторку"
		></Select>
	</Field>
	<!-- Сумма -->
	<Field name="sum" label="Сумма, руб." short={true}>
		<InputMoney {disabled} name="sum" bind:value={$form.sum} klass="form-control text-right" />
	</Field>
	<!-- Платежи по расходу -->
	<Field name="payout_lines" label="Платежи по расходу">
		<div class="badge-container">
			{#each toArray($form.payout_lines) as row}
				<div {disabled} role="button" tabindex="0" on:click|stopPropagation={!disabled && (() => handleEditPayout(row.payout_id))} on:keypress|stopPropagation>
					{formatSum(row.sum) + ' руб. (' + formatDate(row.date_at) + ')'}
				</div>
			{/each}
		</div>
	</Field>

	<ErrorMessage message={$errorsBase} on:click={() => ($errorsBase = null)} />
</Form>

<style>
	.wrapper-info-icon {
		position: absolute;
		top: 0.5rem;
		right: -0.5rem;
		z-index: 10003;
	}
	.repayment_order__popover {
		position: absolute;
		z-index: 10010;
		background-color: #fff;
		border: solid 1px;
		border-radius: 0.3rem;
		box-shadow: 0 0 10px;
		display: block;
		padding: 0.7rem 1rem;
		top: 50px;
	}
</style>
